/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2/3/2021
 * @Example
 */

import React, {useEffect, useRef, useState} from "react";
import {connect}                            from "react-redux";
import {bindActionCreators, compose}        from "redux";
import PropTypes                            from "prop-types";
import {Tab, Tabs}                          from "@material-ui/core";
import {MForm}                              from "../../../common/form-material";
import Modal                                from "../../../common/modal/modal";
import ButtonGeneral                        from "../../../common/button/button-general";
import * as generalActions    from "../../../../redux/general/general_actions";
import * as W09F3005Actions   from "../../../../redux/W0X/W09F3005/W09F3005_actions";
import Config                 from "../../../../config";
import * as _                 from "lodash";
import TabContent, {TabPanel} from "../../../common/tabs/tab-content";
import W09F3005Tab1           from "./W09F3005Tab1";
import W09F3005Tab2           from "./W09F3005Tab2";
import History                from "../../../libs/history";

const W09F3005Popup = (props) => {

    const _initDataForm             = {
        GroupID:      "",
        GroupName:    "",
        GroupPicture: "",
        Admin:        [],
        Member:       [],
        Description:  "",
    };
    const {mode, data}              = props;
    const [error, _setError]        = useState({});
    const [loading, _setLoading]    = useState({});
    const [tabKey, setTabKey]       = useState(0);
    const [dataForm, _setDataForm]  = useState(_initDataForm);
    const [ProcessID, setProcessID] = useState([]);
    const stateLoading              = useRef({});
    const dataOldForm              = useRef(null);
    const userCurrent = useRef(null);
    const keyForMember = useRef("UserID");

    const setError    = (obj) => {
        _setError({...error, ...obj});
        return Object.keys(obj).length !== 0;
    };
    const setLoading  = (obj = {}) => {
        stateLoading.current = {...stateLoading.current, ...obj};
        _setLoading({...loading, ...stateLoading.current});
    };
    const setDataForm = (obj) => {
        _setDataForm({...dataForm, ...obj});
    };

    const loadForm = () => {
        const params = {
            GroupID:  _.get(data, "GroupID", ""),
            Language: Config.language || "84"
        };
        setLoading({main: true});
        props.W09F3005Actions.loadGroupDetail(params, (error, data) => {
            setLoading({main: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const admins      = _.get(data, "admins", []);
                const members     = _.get(data, "members", []);
                const adminMembers     = _.get(data, "all", []);
                let process       = _.get(data, "process", []);
                const adminIDs    = admins.map(d => d.Admin);
                const memberIDs   = members.map(d => d.Member);
                const adminMemberIDs   = adminMembers.map(d => d.AdminMember);
                process           = process.map(d => d.ProcessID);

                const listAdmins  = Config.getListUser(adminIDs, "UserID");
                const listMembers = Config.getListUser(memberIDs, "UserID");
                const listAdminMembers = Config.getListUser(adminMemberIDs, "UserID");
                
                getUserCurrent(listAdmins, listMembers, listAdminMembers);

                const _data = {
                    GroupID:      _.get(data, "GroupID", ""),
                    GroupName:    _.get(data, "GroupName", ""),
                    GroupPicture: _.get(data, "GroupPicture", ""),
                    Description:  _.get(data, "Description", ""),
                    Admin:        listAdmins || [],
                    Member:       listMembers || [],
                    All:       listAdminMembers || [],
                };
                dataOldForm.current = {..._data};
                setDataForm(_data);
                setProcessID(process);
            }
        });
    };

    const getUserCurrent = (admins, members, adminMembers) => {
        try {
            userCurrent.current = null;
            if (adminMembers?.length > 0) {
                const _userCurrent = adminMembers.find(am => am.UserID === Config.profile.UserID);
                if (_userCurrent) return userCurrent.current = {..._userCurrent, Role: 2};
            }
            if (admins?.length > 0) {
                const _userCurrent = admins.find(a => a.UserID === Config.profile.UserID);
                if (_userCurrent) return userCurrent.current = {..._userCurrent, Role: 1};
            }
            if (members?.length > 0) {
                const _userCurrent = members.find(m => m.UserID === Config.profile.UserID);
                if (_userCurrent) return userCurrent.current = {..._userCurrent, Role: 0};
            }
            
            return null;
        } catch (e) {
            console.log("W09F3005 get user current error", e);
        }
    };

    useEffect(() => {
        if (mode !== "add") {
            loadForm();
        } else {
            const _userCurrent = Config.getUser({UserID: Config.profile.UserID || ""});
            if (_userCurrent) {
                _userCurrent.Role = 1;
                userCurrent.current = _userCurrent;
                setDataForm({Admin: [_userCurrent]});
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onHandleChange = (key, e) => {
        if (!key) return false;
        let value = _.cloneDeep(_.get(e, "value", _.get(e, "target.value", e)));
        switch (key) {
            case "ProcessID":
                setProcessID(value);
                break;
            case "Admin": {
                const valueIDs = value.map(v => v[keyForMember.current]);
                let _member = _.cloneDeep(_.get(dataForm, "Member", []));
                let _all = _.cloneDeep(_.get(dataForm, "All", []));
                if (value?.length > 0) {
                    value.forEach((v, idx) => {
                        const indexAM = _all.findIndex(a => a[keyForMember.current] === v[keyForMember.current]);
                        if (indexAM > -1) {
                            value[idx] = null;
                        }
                        else {
                            const indexM = _member.findIndex(m => m[keyForMember.current] === v[keyForMember.current]);
                            if (indexM > -1) {
                                _all.push(v);
                                _member.splice(indexM, 1);
                                value[idx] = null;
                            }
                        }
                    });
                    _all.forEach((am, idx) => {
                        if (!valueIDs.includes(am[keyForMember.current])) {
                            const indexM = _member.findIndex(m => m[keyForMember.current] === am[keyForMember.current]);
                            if (indexM === -1) {
                                _member.push(am);
                            }
                            _all.splice(idx, 1);
                        }
                    });
                }
                else {
                    _all.forEach(am => {
                        const indexM = _member.findIndex(m => m[keyForMember.current] === am[keyForMember.current]);
                        if (indexM === -1) {
                            _member.push(am);
                        }
                    });
                    _all = [];
                }
                value = value.filter(v => v);
                setDataForm({[key]: value, Member: _member, All: _all});
                break;
            }
            case "Member": {
                const valueIDs = value.map(v => v[keyForMember.current]);
                let _admin = _.cloneDeep( _.get(dataForm, "Admin", []));
                let _all = _.cloneDeep(_.get(dataForm, "All", []));
                if (value?.length > 0) {
                    value.forEach((v, idx) => {
                        const indexAM = _all.findIndex(a => a[keyForMember.current] === v[keyForMember.current]);
                        if (indexAM > -1) {
                            value[idx] = null;
                        }
                        else {
                            const indexA = _admin.findIndex(a => a[keyForMember.current] === v[keyForMember.current]);
                            if (indexA > -1) {
                                _all.push(v);
                                _admin.splice(indexA, 1);
                                value[idx] = null;
                            }
                        }
                    });
                    _all.forEach((am, idx) => {
                        if (!valueIDs.includes(am[keyForMember.current])) {
                            const indexA = _admin.findIndex(a => a[keyForMember.current] === am[keyForMember.current]);
                            if (indexA === -1) {
                                _admin.push(am);
                            }
                            _all.splice(idx, 1);
                        }
                    });
                }
                else {
                    _all.forEach(am => {
                        const indexA = _admin.findIndex(a => a[keyForMember.current] === am[keyForMember.current]);
                        if (indexA === -1) {
                            _admin.push(am);
                        }
                    });
                    _all = [];
                }
                value = value.filter(v => v);
                setDataForm({[key]: value, Admin: _admin, All: _all});
                break;
            }
            default:
                setDataForm({[key]: value});
                break;
        }
    };

    const onChangeTab = (e, value) => {
        setTabKey(value);
    };

    const onSave = async () => {
        const isEdit    = mode === "edit";
        const _Admin    = _.get(dataForm, "Admin", []);
        const _Member   = _.get(dataForm, "Member", []);
        const _All      = _.get(dataForm, "All", []);

        const Admin     = _Admin && _Admin.map(d => d[keyForMember.current]);
        const Member    = _Member && _Member.map(d => d[keyForMember.current]);
        const All       = _All && _All.map(d => d[keyForMember.current]);

        const AdminVal  = [...Admin, ...All];
        const MemberVal = [...Member, ...All];

        let params = {
            GroupName:    _.get(dataForm, "GroupName", ""),
            GroupPicture: _.get(dataForm, "GroupPicture", ""),
            Description:  _.get(dataForm, "Description", ""),

            All:     JSON.stringify(All),
            Admin:   JSON.stringify(Admin),
            Member:  JSON.stringify(Member),
            Process: JSON.stringify(ProcessID),
        };

        if (isEdit) params.GroupID = _.get(dataForm, "GroupID", "");
        const validateRules = [
            {
                name:  "GroupName",
                rules: ["isRequired"],
                value: params.GroupName || ""
            },
            {
                name:  "Admin",
                rules: ["isRequired"],
                value: AdminVal && AdminVal.length > 0 ? AdminVal : null
            },
            {
                name:  "Member",
                rules: ["isRequired"],
                value: MemberVal && MemberVal.length > 0 ? MemberVal : null
            },

        ];
        const validateForm  = MForm.formValidation(validateRules);
        if (Object.keys(validateForm).length > 0) {
            setError(validateForm);
            return false;
        } else {
            setLoading({main: true});
            props.W09F3005Actions[isEdit ? "update" : "save"](params, async (error, data) => {
                setLoading({main: false});
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                }
                if (data) {
                    if (data.Status === 1) {
                        let message = data.Message || Config.lang("Loi_chua_xac_dinh");
                        Config.popup.show("INFO", message);
                        return false;
                    }
                    await saveHistory(_.get(data, "GroupID", ""));
                }
                Config.notify.show('success', Config.lang("Luu_thanh_cong"), 2000);
                onClose();
            });
        }
    };

    const saveHistory = async (GroupID) => {
        const TransID = GroupID ? GroupID : _.get(data, "GroupID", "");
        if (TransID) {
            const Admin = dataForm?.Admin ?? [];
            const Member = dataForm?.Member ?? [];
            const All = dataForm?.All ?? [];

            const oldAdmin = dataOldForm.current?.Admin ?? [];
            const oldMember = dataOldForm.current?.Member ?? [];
            const oldMemberAdmin = dataOldForm.current?.All ?? [];
            
            //re-create data..
            const _data = {
                GroupName: _.get(dataForm, "GroupName", ""),
            };
            const _dataOld = {
                GroupName: _.get(dataOldForm.current, "GroupName", ""),
            };

            //Init captions..
            const captions = {
                GroupName: "Ten_nhom",
            };
            const detailCaptions = {
                EmployeeID: "Ma_nhan_vien",
                EmployeeName: "Ten_nhan_vien",
            };

            const options = {
                data: _data,
                captions: captions,
                dataCompare: _dataOld,
                // action: mode === "add" ? 0 : 1,
                ModuleID: "D09",
                TransID: TransID,
                TransactionID: "W09F3000",
                TransactionName: "Nhóm",
            };
            const history = new History({...options, action: mode === "add" ? 0 : 1}); //Init history
            history.createDetailHistory("Thanh_vien", Member, oldMember, detailCaptions,keyForMember.current,  null, options ); //Create detail..
            history.createDetailHistory("Quan_tri_vien", Admin, oldAdmin, detailCaptions,keyForMember.current,  null, options ); //Create detail..
            history.createDetailHistory("Thanh_vien_vai_tro_quan_tri", All, oldMemberAdmin, detailCaptions, keyForMember.current,  null, options ); //Create detail..
            if (history.get().length > 0) {
                await history.save();
            }
        } else {
            Config.notify.show("error", Config.lang("Luu_lich_su_khong_thanh_cong"), 2000);
        }

    };

    const onClose = () => {
        const {onClose} = props;
        if (onClose) onClose();
    };

    const disabled = Boolean(Object.keys(loading).find(l => loading[l]));
    return (
        <>
            <Modal.Content>
                <div style={{marginTop: -15}}>
                    <Tabs
                        indicatorColor={"primary"}
                        textColor={"primary"}
                        value={tabKey}
                        onChange={onChangeTab}
                        // className={classes.tabs}
                    >
                        <Tab style={{
                            minWidth:  70,
                            minHeight: 42
                        }} value={0} label={Config.lang("Thong_tin_chung")}/>
                        <Tab style={{
                            minWidth:  70,
                            minHeight: 42
                        }} value={1} label={Config.lang("Quy_trinh_ap_dung")}/>
                    </Tabs>
                </div>
                <TabContent
                    activeKey={tabKey}
                    disableSwipe={true}
                    lazyLoading={false}
                    // className={classes.tabContent}
                >
                    <TabPanel index={0}>
                        <W09F3005Tab1
                            error={error}
                            disabled={disabled}
                            dataForm={dataForm}
                            onChange={onHandleChange}
                        />
                    </TabPanel>
                    <TabPanel index={1}>
                        <W09F3005Tab2
                            disabled={disabled}
                            dataForm={{ProcessID}}
                            onChange={onHandleChange}
                        />
                    </TabPanel>
                </TabContent>
            </Modal.Content>
            <Modal.Actions style={{justifyContent: "space-between"}}>
                <div/>
                <div>
                    <ButtonGeneral
                        name={Config.lang("Luu")}
                        typeButton={"save"}
                        disabled={disabled}
                        style={{textTransform: "uppercase"}}
                        size={"large"}
                        onClick={onSave}
                    />
                </div>
            </Modal.Actions>
        </>
    );
}

W09F3005Popup.propTypes = {
    open: PropTypes.bool,
    mode: PropTypes.string,
    data: PropTypes.any,

    onClose: PropTypes.func
};

export default compose(connect(state => ({
    getProcess: state.W09F3005.getProcess,
}), dispatch => ({
    W09F3005Actions: bindActionCreators(W09F3005Actions, dispatch),
    generalActions:  bindActionCreators(generalActions, dispatch)
})))(W09F3005Popup);
