/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2/3/2021
 * @Example
 */

import React, {useRef, useState}     from "react";
import {connect}                     from "react-redux";
import {bindActionCreators, compose} from "redux";
import PropTypes                     from "prop-types";
import {Row, Col}                    from "react-bootstrap";
import {InputLabel}                  from "@material-ui/core";
import {TextField}                   from "../../../common/form-material";
import * as W09F3005Actions          from "../../../../redux/W0X/W09F3005/W09F3005_actions";
import Config                        from "../../../../config";
import {Editor}                      from "@tinymce/tinymce-react";
import * as _                        from "lodash";
import FormControl                   from "@material-ui/core/FormControl";
import CbEmployees                   from "../../../common/popup-employee/InputEmployee";
import PopupEmployees                from "../../../common/popup-employee/PopupEmployees";

const W09F3005Tab1 = React.memo((props) => {

    const {error, disabled, dataForm}         = props;
    const [isOpenCombo, setIsOpenCombo]       = useState(false);
    const [title, setTitle]                   = useState("");
    const [dataCbEmployee, setDataCbEmployee] = useState([]);
    const isRenderEmployees                   = useRef(false);
    const previousCboEmployees                   = useRef(null);

    const onHandleChange = (key, e) => {
        const {onChange} = props;
        if (onChange) onChange(key, e);
    };

    const onOpenEmployees = (mode, title, data) => {
        if (mode) {
            const {onChange} = props;
            switch (title) {
                case 'DHR_Quan_tri':
                    setIsOpenCombo(!isOpenCombo);
                    if (onChange) onChange("Admin", {value: data});
                    break;
                case 'DHR_Thanh_vien':
                    setIsOpenCombo(!isOpenCombo);
                    if (onChange) onChange("Member", {value: data});
                    break;
                default:
                    break;
            }
            setDataCbEmployee([]);
        } else {
            setDataCbEmployee(data);
            setIsOpenCombo(!isOpenCombo);
            if (previousCboEmployees.current === title) {
                isRenderEmployees.current = true;
            }
            else {
                isRenderEmployees.current = false;
            }
            previousCboEmployees.current = title;
            setTitle(title);
        }
    }

    return (
        <>
            {(isOpenCombo || isRenderEmployees.current) && <PopupEmployees
                isOpenCombo={isOpenCombo}
                data={dataCbEmployee}
                onCloseEmployees={onOpenEmployees}
                title={title}
                FormID={"W09F3000"}
                valueExpr={"UserID"}
                displayExpr={"EmployeeName"}
                autoSearch
            />}
            <Row className={"mgb5"}>
                <Col xs={12} sm={12} md={12} lg={12}>
                    <TextField
                        error={_.get(error, "GroupName", "")}
                        label={Config.lang("DHR_Ten_nhom")}
                        variant={"standard"}
                        margin={"normal"}
                        value={_.get(dataForm, "GroupName", "")}
                        disabled={disabled}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        required={true}
                        onChange={(e) => onHandleChange("GroupName", e)}
                        fullWidth
                    />
                </Col>
            </Row>
            <Row className={"mgb5"}>
                <Col xs={12} sm={12} md={12} lg={12}>
                    <CbEmployees
                        label={Config.lang('DHR_Quan_tri')}
                        shrink={true}
                        InputLabelProps={{
                            shrink:   true,
                            required: true,
                        }}
                        valueExpr={"UserID"}
                        displayExpr={"EmployeeName"}
                        style={{cursor: 'pointer'}}
                        disabled={false}
                        value={[..._.get(dataForm, "Admin", []), ..._.get(dataForm, "All", [])]}
                        error={_.get(dataForm, "Admin", []).length > 0 || _.get(dataForm, "All", []).length > 0 ? false : _.get(error, "Admin", "")}
                        onClick={() => onOpenEmployees(false, 'DHR_Quan_tri', [..._.get(dataForm, "Admin", []), ..._.get(dataForm, "All", [])])}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                    <CbEmployees
                        label={Config.lang('DHR_Thanh_vien')}
                        shrink={true}
                        InputLabelProps={{
                            shrink:   true,
                            required: true,
                        }}
                        valueExpr={"UserID"}
                        displayExpr={"EmployeeName"}
                        style={{cursor: 'pointer'}}
                        disabled={false}
                        value={[..._.get(dataForm, "Member", []), ..._.get(dataForm, "All", [])]}
                        error={_.get(dataForm, "Member", []).length > 0 || _.get(dataForm, "All", []).length > 0 ? false : _.get(error, "Member", "")}
                        onClick={() => onOpenEmployees(false, 'DHR_Thanh_vien', [..._.get(dataForm, "Member", []), ..._.get(dataForm, "All", [])])}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                    <FormControl variant={"standard"}
                                 margin={"normal"}
                                 fullWidth={true}>
                        <InputLabel
                            shrink={true}
                        >
                            {Config.lang("DHR_Mo_ta")}
                        </InputLabel>
                        <div style={{marginTop: 26}}>
                            <Editor
                                value={_.get(dataForm, "Description", "")}
                                init={{
                                    height:  360,
                                    plugins: "advlist lists link image",
                                    toolbar:
                                             "styleselect | bold italic forecolor | bullist numlist | link image| removeformat",
                                    menubar: false,
                                }}
                                blurredEditor={true}
                                onEditorChange={(e) => onHandleChange("Description", e)}
                            />
                        </div>
                    </FormControl>
                </Col>
            </Row>
        </>
    );
}, (prevProps, nextProps) => {
    return JSON.stringify(prevProps.dataForm) === JSON.stringify(nextProps.dataForm)
        && JSON.stringify(prevProps.error) === JSON.stringify(nextProps.error)
        && prevProps.disabled === nextProps.disabled
});

W09F3005Tab1.propTypes = {
    error:    PropTypes.object,
    disabled: PropTypes.bool,
    dataForm: PropTypes.object,

    onChange: PropTypes.func
};

export default compose(connect(state => ({}), dispatch => ({
    W09F3005Actions: bindActionCreators(W09F3005Actions, dispatch),
})))(W09F3005Tab1);
