import { useState } from 'react';

export const useDateRangePicker = (initialValue) => {
    const [dateFrom, setDateFrom] = useState(initialValue?.[0] ?? null);
    const [dateTo, setDateTo] = useState(initialValue?.[1] ?? null);

    return {
        value: [dateFrom, dateTo],
        setDateFrom,
        setDateTo,
        reset: () => {
            setDateFrom(null);
            setDateTo(null);
        },
        bind: {
            value: [dateFrom, dateTo],
            onChange: (event) => {
                setDateFrom(event?.value?.[0] ?? null);
                setDateTo(event?.value?.[1] ?? null);
            },
        },
    };
};

export const useDropdown = (initialValue, callback) => {
    const [value, setValue] = useState(initialValue);

    return {
        value,
        setValue,
        reset: () => setValue(null),
        bind: {
            value,
            onChange: (event) => {
                setValue(event?.value ?? null);
                callback && callback(event?.value ?? null);
            },
        },
    };
};
