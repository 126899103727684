/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 12/10/2021
 * @Example
 */

import { makeStyles } from '@material-ui/styles';
import { Button, Checkbox, Collapse, DateRangePicker, Dropdown, Label, Typography } from 'diginet-core-ui/components';
import Icon from 'diginet-core-ui/icons';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Config from '../../../../config';
import * as approvalActions from '../../../../redux/approvals/approvals_actions';
import * as generalActions from '../../../../redux/general/general_actions';
import * as W09F2292Actions from '../../../../redux/W0X/W09F2292/W09F2292_actions';
import Approvals from '../../../approvals/approvals';
import ActionToolbar from '../../../common/toolbar/action-toolbar';
import Filter from '../../../filter/filter';

const useStyles = makeStyles((theme) => ({
    divFormContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
    divFormInfo: {
        display: 'grid',
        gap: 10,
        gridTemplateColumns: 'repeat(auto-fit, minmax(210px, 1fr))',
    },
    divOldInfo: {
        display: 'flex',
        gap: 5,
        alignItems: 'center',
        cursor: 'pointer',
    },
}));

const W09F2292 = (props) => {
    const classes = useStyles();

    const { location } = props;

    const [iPermission, setIPermission] = useState(0);
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState({});
    const [loadingCboEmployees, setLoadingCboEmployees] = useState(false);
    const [dataCboEmployees, setDataCboEmployees] = useState({
        rows: [],
        total: 0,
    });
    const [filter, setFilter] = useState(() => ({
        ApprovalStatus: null,
        EmployeeID: '',
        DutyID: '',
        TransTypeID: '',
        DateFrom: null,
        DateTo: null,
    }));
    const [openOldInfo, setOpenOldInfo] = useState(false);
    const [dataDynamicColumnSalary, setDataDynamicColumnSalary] = useState([]);
    const [dataDynamicColumnCaption, setDataDynamicColumnCaption] = useState([]);
    const [dataFilter, setDataFilter] = useState(() => {
        return {
            UserID: Config?.profile?.UserID ?? '',
            ApprovalStatus: filter?.ApprovalStatus ?? null,
            EmployeeID: filter?.EmployeeID ?? '',
            DutyID: filter?.DutyID ?? '',
            TransTypeID: filter?.TransTypeID ?? '',
            DateFrom: filter?.DateFrom ?? null,
            DateTo: filter?.DateTo ?? null,
        };
    });

    const approvalRef = useRef(null);

    const dispatch = useDispatch();
    const dataCboStatus = useSelector((state) => state?.approvals?.getCboStatus ?? []);
    const dataCboDuty = useSelector((state) => state.general.getCboDuty2);
    const getCboTransTypes = useSelector((state) => state.general.getCboTransTypes);

    const paramFilterCboEmployees = {
        skip: 0,
        limit: 10,
        search: '',
    };

    const loadPermission = () => {
        dispatch(
            generalActions.getPermission('W09F2292', (isPer) => {
                setIPermission(isPer);
            })
        );
    };

    const loadDynamicColumnSalary = () => {
        const params = {
            Language: Config?.language ?? '84',
        };
        dispatch(
            W09F2292Actions.getDynamicColumnSalary(params, (error, data) => {
                if (error) {
                    Config.popup.show('ERROR', error || Config.lang('Loi_chua_xac_dinh'));
                    return false;
                }
                if (data) {
                    setDataDynamicColumnSalary(data);
                }
            })
        );
    };

    const loadDynamicColumnCaption = () => {
        const params = {
            Language: Config?.language ?? '84',
        };
        dispatch(
            W09F2292Actions.getDynamicColumnCaption(params, (error, data) => {
                if (error) {
                    Config.popup.show('ERROR', error || Config.lang('Loi_chua_xac_dinh'));
                    return false;
                }
                if (data) {
                    setDataDynamicColumnCaption(data);
                }
            })
        );
    };

    useEffect(() => {
        loadPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!iPermission) return;
        loadDynamicColumnSalary();
        loadDynamicColumnCaption();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iPermission]);

    useEffect(() => {
        const VoucherID = location?.state?.voucher_id ?? null;
        if (VoucherID) {
            loadFormInfo(VoucherID, (dt) => {
                dt.VoucherID = VoucherID;
                approvalRef.current && approvalRef.current.showPopup(dt);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const loadCboStatus = () => {
        const params = {
            FormID: 'STANDARD',
            Language: Config.language || '84',
        };
        dispatch(
            approvalActions.getCboStatus(params, (error) => {
                if (error) {
                    Config.popup.show('ERROR', error);
                    return false;
                }
            })
        );
    };

    const loadCboEmployees = (isReset) => {
        const param = {
            Type: 'EmployeeID',
            FormID: 'W09F2292',
            Language: Config.language || '84',
            ...paramFilterCboEmployees,
        };
        setLoadingCboEmployees(true);
        dispatch(
            generalActions.getCboEmployees(param, (err, data) => {
                setLoadingCboEmployees(false);
                if (err) return Config.popup.show('ERROR', err);
                if (data) {
                    const rows = data?.rows ?? data;
                    const total = data?.total ?? data?.length;
                    setDataCboEmployees({
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total,
                    });
                }
            })
        );
    };

    const loadCboDuty = () => {
        dispatch(
            generalActions.getCboDuty2((error) => {
                if (error) {
                    Config.popup.show('ERROR', error);
                    return false;
                }
            })
        );
    };

    const loadCboTransType = () => {
        dispatch(generalActions.getCboTransTypes());
    };

    const onLoadCboFilter = () => {
        loadCboStatus();
        loadCboEmployees();
        loadCboDuty();
        loadCboTransType();
    };

    const renderFilter = () => {
        const { EmployeeID, ApprovalStatus, DateFrom, DateTo } = filter;
        return (
            <Filter
                isUseDDCore
                placeholder={Config.lang('Noi_dung_can_tim_kiem')}
                showHeader={false}
                openOnFieldClick={true}
                onOpenLoaded={onLoadCboFilter}
                renderFilter={() => {
                    return (
                        <>
                            <DateRangePicker
                                clearAble
                                controls
                                value={[DateFrom ?? null, DateTo ?? null]}
                                label={Config.lang('Ngay_de_xuat')}
                                onChange={(e) => filterChange('Date', e)}
                                placeholder={`${Config.lang('Tu_ngay')} - ${Config.lang('Den_ngay')}`}
                                returnFormat={'YYYY-MM-DD'}
                                viewType={'outlined'}
                            />
                            <Dropdown
                                allowSearch
                                clearAble
                                dataSource={dataCboEmployees.rows}
                                total={dataCboEmployees.total}
                                skip={paramFilterCboEmployees.skip}
                                limit={paramFilterCboEmployees.limit}
                                displayExpr={'{EmployeeID} - {EmployeeName}'}
                                renderSelectedItem={'EmployeeName'}
                                valueExpr={'EmployeeID'}
                                placeholder={Config.lang('Chon')}
                                label={Config.lang('Nhan_vien')}
                                onChange={(e) => filterChange('EmployeeID', e)}
                                value={EmployeeID ?? null}
                                viewType={'outlined'}
                                loading={loadingCboEmployees}
                                onInput={(e) => {
                                    paramFilterCboEmployees.search = e?.target?.value ?? '';
                                    paramFilterCboEmployees.skip = 0;
                                    loadCboEmployees(true);
                                }}
                                onLoadMore={(e) => {
                                    paramFilterCboEmployees.skip = e?.skip ?? 0;
                                    paramFilterCboEmployees.limit = e?.limit ?? 10;
                                    loadCboEmployees();
                                }}
                            />
                            <Dropdown
                                clearAble
                                valueExpr={'ApprovalStatus'}
                                displayExpr={'AppStatusName'}
                                placeholder={Config.lang('Chon')}
                                label={Config.lang('Trang_thai_duyet')}
                                onChange={(e) => filterChange('ApprovalStatus', e)}
                                dataSource={dataCboStatus}
                                value={ApprovalStatus ?? null}
                                viewType={'outlined'}
                            />
                            <Dropdown
                                clearAble
                                valueExpr={'DutyID'}
                                displayExpr={'DutyName'}
                                placeholder={Config.lang('Chon')}
                                label={Config.lang('Chuc_vu')}
                                onChange={(e) => filterChange('DutyID', e)}
                                dataSource={dataCboDuty}
                                value={filter?.DutyID ?? null}
                                viewType={'outlined'}
                            />
                            <Dropdown
                                clearAble
                                valueExpr={'TransTypeID'}
                                displayExpr={'TransTypeName'}
                                placeholder={Config.lang('Chon')}
                                label={Config.lang('Nghiep_vu')}
                                onChange={(e) => filterChange('TransTypeID', e)}
                                dataSource={getCboTransTypes ?? []}
                                value={filter?.TransTypeID ?? null}
                                viewType={'outlined'}
                            />
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    text={Config.lang('Tim_kiem')}
                                    viewType={'outlined'}
                                    color={'primary'}
                                    onClick={onSearch}
                                    startIcon={'Search'}
                                />
                            </div>
                        </>
                    );
                }}
            />
        );
    };

    const filterChange = (key, data) => {
        switch (key) {
            case 'ApprovalStatus':
                setFilter({ ...filter, [key]: data?.value ?? null });
                break;
            case 'EmployeeID':
            case 'DutyID':
            case 'TransTypeID':
                setFilter({ ...filter, [key]: data?.value ?? '' });
                break;
            case 'Date':
                setFilter({ ...filter, DateFrom: data?.value?.[0] ?? null, DateTo: data?.value?.[1] ?? null });
                break;
            default:
                break;
        }
    };

    const onSearch = () => {
        setDataFilter({ ...dataFilter, ...filter });
    };

    const onSelectionChanged = (e) => {
        if (!e) return false;
        loadFormInfo(e.VoucherID);
    };

    const loadFormInfo = (voucherID, cb) => {
        const params = {
            DivisionID: Config.getDivisionID() ?? '',
            FormID: 'W09F2292',
            UserID: Config?.profile?.UserID ?? '',
            Language: Config?.language ?? '84',
            VoucherID: voucherID ?? '',
        };
        setLoading(true);
        dispatch(
            approvalActions.getFormInfo(params, (err, dt) => {
                setLoading(false);
                if (err) {
                    const msg = err?.message ?? Config.lang('Loi_chua_xac_dinh');
                    return Config.popup.show('ERROR', msg);
                }
                if (dt) {
                    setDataForm(dt);
                    cb && cb(dt);
                }
            })
        );
    };

    const renderFormInfo = (data) => {
        return (
            <div className={classes.divFormContainer}>
                <div className={classes.divFormInfo}>
                    <div>
                        <Label>{Config.lang('Nghiep_vu')}</Label>
                        <Typography>{data?.TransTypeName ?? ''}</Typography>
                    </div>
                    <div>
                        <Label>{Config.lang('Nhan_vien')}</Label>
                        <Typography>{data?.EmployeeName ?? ''}</Typography>
                    </div>
                </div>
                <div className={classes.divFormInfo}>
                    {data?.TransTypeID !== '0013' && <div>
                        <Label>{Config.lang('Chuc_vu_moi')}</Label>
                        <Typography>{data?.ProDutyName ?? ''}</Typography>
                    </div>}
                    <div>
                        <Label>{Config.lang('Ngay_hieu_luc')}</Label>
                        <Typography>{data?.ProDutyDate ? moment(data?.ProDutyDate).format('DD/MM/YYYY') : ''}</Typography>
                    </div>
                </div>
                <div>
                    <Label>{Config.lang('Ly_do')}</Label>
                    <Typography>{data?.ProReasonName ?? ''}</Typography>
                </div>
                <div>
                    <Label>{Config.lang('Ghi_chu')}</Label>
                    <Typography>{data?.ProNotice ?? ''}</Typography>
                </div>
                {data?.IsSalAdjust === 1 && <Checkbox readOnly checked={true} label={Config.lang('Dieu_chinh_luong')} color={'primary'} />}
                {data?.IsSalAdjust === 1 && (
                    <div className={classes.divFormInfo}>
                        {dataDynamicColumnSalary.map((i, index) => {
                            return i?.Disabled ? null : (
                                <Typography key={index}>
                                    {i?.CaptionName ?? ''}: {Number(data?.[`ProBaseSalary${i?.Code?.slice(4) ?? '01'}`] ?? '')?.toLocaleString('en-US')}
                                </Typography>
                            );
                        })}
                        {dataDynamicColumnCaption.map((i, index) => {
                            return i?.Disabled ? null : (
                                <Typography key={index}>
                                    {i?.CaptionName ?? ''}: {Number(data?.[`ProSalCoefficient${i?.Code?.slice(2) ?? '01'}`] ?? '')?.toLocaleString('en-US')}
                                </Typography>
                            );
                        })}
                    </div>
                )}
                <div className={classes.divOldInfo} onClick={() => setOpenOldInfo((openOldInfo) => !openOldInfo)}>
                    <Typography>{Config.lang('Thong_tin_cu')}</Typography>
                    <Icon name={openOldInfo ? 'ArrowUp' : 'ArrowDown'} viewBox />
                </div>
                <Collapse open={openOldInfo} className={classes.divFormContainer}>
                    <Typography>
                        {Config.lang('Chuc_vu')}: {data?.TempDutyName ?? ''}
                    </Typography>
                    <div className={classes.divFormInfo}>
                        {dataDynamicColumnSalary.map((i, index) => {
                            return i?.Disabled ? null : (
                                <Typography key={index}>
                                    {i?.CaptionName ?? ''}: {Number(data?.[`TempBaseSalary${i?.Code?.slice(4) ?? '01'}`] ?? '')?.toLocaleString('en-US')}
                                </Typography>
                            );
                        })}
                        {dataDynamicColumnCaption.map((i, index) => {
                            return i?.Disabled ? null : (
                                <Typography key={index}>
                                    {i?.CaptionName ?? ''}: {Number(data?.[`TempSalCoefficient${i?.Code?.slice(2) ?? '01'}`] ?? '')?.toLocaleString('en-US')}
                                </Typography>
                            );
                        })}
                    </div>
                </Collapse>
            </div>
        );
    };

    return (
        <>
            <ActionToolbar title={Config.lang('Duyet_mien_nhiem')} />
            <Approvals
                ref={approvalRef}
                parentProps={props}
                FormID={'W09F2292'}
                loading={loading}
                dataFilter={dataFilter}
                dataForm={dataForm}
                singleClick
                allowHistoryApproval
                disabledApproval={iPermission < 2}
                filterRender={renderFilter}
                formInfoRender={renderFormInfo}
                onSelectionChanged={onSelectionChanged}
            />
        </>
    );
};

export default W09F2292;
