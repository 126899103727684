/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 10/11/2021
 * @Example
 */

import React, {useState, useEffect, useRef} from "react";
import {compose, bindActionCreators}        from "redux";
import {connect}                            from "react-redux";
import {FormGroup}                          from "react-bootstrap";
import {MForm}                              from "../../../common/form-material";
import Config                               from "../../../../config";
import * as W09F2291Actions                 from "../../../../redux/W0X/W09F2291/W09F2291_actions";
import * as generalActions                  from "../../../../redux/general/general_actions";
import {
    Dropdown,
    MoneyInput,
    Typography,
    Checkbox,
    TextInput,
    Attachment,
    Button,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    DatePicker,
    Modal,
    ModalHeader,
    ModalBody, ModalFooter, Avatar, Col, Row,
}                                           from 'diginet-core-ui/components';
import CDN                                  from "../../../CDN";
import History                              from "../../../libs/history";
import moment                               from "moment";
import PropTypes                            from "prop-types";

const initProBaseSalary = () => {
    let data = {};
    [...Array(4).keys()].forEach(i => {
        data['ProBaseSalary0' + (i+1)] = null
    });
    return data;
};

const initTempBaseSalary = () => {
    let data = {};
    [...Array(4).keys()].forEach(i => {
        data['TempBaseSalary0' + (i+1)] = null
    });
    return data;
};

const initProSalCoefficient = () => {
    let data = {};
    [...Array(20).keys()].forEach(i => {
         data["ProSalCoefficient" + (i<9 ? `0${i+1}` : i+1)] = null;
    });
    return data;
};

const initTempSalCoefficient = () => {
    let data = {};
    [...Array(20).keys()].forEach(i => {
       data['TempSalCoefficient' + (i < 9 ? `0${i+1}` : i+1)] = null;
    });
    return data;
};

const initDataOld = () => {
    return {
        LinkTransID : "",
        TempDutyID  : "",
        TempDutyName: "",
        ...initTempBaseSalary(),
        ...initTempSalCoefficient()
    };
};

const initDataForm = {
    TransTypeID  : "",
    Employee     : "",
    EmployeeName : "",
    ProDutyDate  : "",
    ProDutyID    : "",
    ProReasonID  : "",
    ProNotice    : "",
    LinkTransID  : "",
    TempDutyID   : "",
    TransTypeName: "",
    ProDutyName  : "",
    ProReasonName: "",
};
const W09F2291     = (props) => {
    const {mode, open, onClose, data, getCboDuty, getCboReasons, getColumns, getCaptions, isPermission} = props;
    const [errorHelperText, _setErrorHelperText]                                          = useState({});
    const [dataForm, setDataForm]                                                         = useState(initDataForm);
    const [dataOldForm, setDataOldForm]                                                   = useState(initDataOld);
    const [loadingDataForm, setLoadingDataForm]                                           = useState(false);
    const [loadingCboEmployees, setLoadingCboEmployees]                                   = useState(false);
    const [loadingCboDuty, setLoadingCboDuty]                                             = useState(false);
    const [loadingCboReason, setLoadingCboReason]                                         = useState(false);
    const [loadingCboTranstype, setLoadingCboTranstype]                                   = useState(false);
    const [loadingCboTranstypeID, setLoadingCboTranstypeID]                               = useState(false);
    const [loadingAttachments, setLoadingAttachments]                                     = useState(false);
    const [dataCboEmployees, setDataCboEmployees]                                         = useState([]);
    const [dataOldAttachments, setDataOldAttachments]                                     = useState([]);
    const [dataCboTranstype, setDataCboTranstype]                                         = useState([]);
    const [dataProBaseSalary, setDataProBaseSalary]                                       = useState(initProBaseSalary);
    const [dataProSalCoefficient, setDataProSalCoefficient]                               = useState(initProSalCoefficient);
    const [isSalAdjust, setIsSalAdjust]                                                   = useState(false);
    const [isEdit, setIsEdit]                                                             = useState(false);
    const [isView, setIsView]                                                             = useState(false);
    const [isSaved, setIsSaved]                                                           = useState(false);
    const attachments                                                                     = useRef([]);
    const deletedFile                                                                     = useRef([]);
    const dataOld                                                                         = useRef({});
    const isChanged                                                                       = useRef(false);
    const setDefaultTransTypeID                                                           = useRef(false);
    const dtTransTypeID                                                                   = useRef("");

    const loadCboReason = () => {
        setLoadingCboReason(true);
        props.w09f2291Actions.getCboReason(error => {
            setLoadingCboReason(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    const loadComlumns = () => {
        props.w09f2291Actions.getColumns(error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };


    const loadCaptions = () => {
        props.w09f2291Actions.getCaptions(error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    const loadCboEmployees = () => {
        const param = {
            Language   : Config.language || 84,
            FormID     : "W09F2291",
            TransTypeID: dataForm?.TransTypeID ?? "",
            Mode       : 0,
            skip       : 0,
            limit      : 20
        };
        setLoadingCboEmployees(true);
        props.w09f2291Actions.getEmployee(param, (error, data) => {
            setLoadingCboEmployees(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            setDataCboEmployees(getUser(data || []))
        });
    };

    const loadAttachments = () => {
        const param = {
            KeyID    : data?.ProTransID ?? "",
            TableName: "D09T2060"
        };
        setLoadingAttachments(true);
        props.generalActions.getAttachmentsByTransID(param, (err, data) => {
            setLoadingAttachments(false);
            if (err) {
                Config.popup.show('ERROR', err);
                return false;
            }
            if (data && data.length > 0) {
                setDataOldAttachments(data);
            }
        });
    };

    const loadForm = () => {
        const params = {
            Language  : Config.language || 84,
            FormID    : "W09F2291",
            ProTransID: data?.ProTransID ?? "",
        };
        setLoadingDataForm(true);
        props.w09f2291Actions.getGrids(params, (error, data) => {
            setLoadingDataForm(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data && data[0]) {
                const proDutyDate = moment(data[0]?.ProDutyDate ?? "").isValid() ?
                    moment(data[0]?.ProDutyDate).format("YYYY-MM-DD") : "";
                dataOld.current   = {
                    ...data[0],
                    ProDutyDate: proDutyDate
                };
                setIsSalAdjust(!!data[0]?.IsSalAdjust);
                setDataCboEmployees(getUser(data || []));
                setData({
                    ProTransID   : data[0]?.ProTransID ?? "",
                    ProDutyDate  : proDutyDate,
                    ProDutyID    : data[0]?.ProDutyID ?? "",
                    ProDutyName  : data[0]?.ProDutyName ?? "",
                    ProReasonID  : data[0]?.ProReasonID ?? "",
                    ReasonName   : data[0]?.ReasonName ?? "",
                    ProNotice    : data[0]?.ProNotice ?? "",
                    TransTypeID  : data[0]?.TransTypeID ?? "",
                    TransTypeName: data[0]?.TransTypeName ?? "",
                    TempDutyID   : data[0]?.TempDutyID ?? "",
                    TempDutyName : data[0]?.TempDutyName ?? "",
                    Employee     : data[0]?.EmployeeID ?? "",
                    EmployeeName : data[0]?.EmployeeName ?? "",
                    LinkTransID  : data[0]?.LinkTransID ?? ""
                });
                const dataProBaseSalary      = getDataByKeyID(data[0], "ProBaseSalary", "ProBaseSalary") ?? {};
                const dataProSalCoefficient  = getDataByKeyID(data[0], "ProSalCoefficient", "ProSalCoefficient") ?? {};
                const dataTempBaseSalary     = getDataByKeyID(data[0], "TempBaseSalary", "TempBaseSalary") ?? {};
                const dataTempSalCoefficient = getDataByKeyID(data[0], "TempSalCoefficient", "TempSalCoefficient") ?? {};
                setDataProBaseSalary({...dataProBaseSalary});
                setDataProSalCoefficient({...dataProSalCoefficient});
                setDataOldForm({
                    ...dataOldForm,
                    LinkTransID : data[0]?.LinkTransID ?? "",
                    TempDutyID  : data[0]?.TempDutyID ?? "",
                    TempDutyName: data[0]?.TempDutyName ?? "", ...dataTempBaseSalary, ...dataTempSalCoefficient
                })
            }
        });
    };

    const loadCboDuty = () => {
        setLoadingCboDuty(true);
        props.generalActions.getCboDuty2(error => {
            setLoadingCboDuty(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    const loadCboTranstypes = () => {
        setLoadingCboTranstype(true);
        props.generalActions.getCboTransTypes((error, data) => {
            setLoadingCboTranstype(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (mode === "add") {
                setData({TransTypeID: data?.[0]?.TransTypeID});
                dtTransTypeID.current = data?.[0]?.TransTypeID;
            }
            setDataCboTranstype(data || []);
        });
    };


    const loadDataTransTypeID = (TransTypeID = "") => {
        const params = {
            Language   : Config.language || 84,
            FormID     : "W09F2291",
            TransTypeID: TransTypeID ?? "",
            Mode       : 0,
            skip       : 0,
            limit      : 20
        };
        setLoadingCboTranstypeID(true);
        props.w09f2291Actions.getEmployee(params, (error, data) => {
            setLoadingCboTranstypeID(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                setData({
                    TransTypeID : TransTypeID,
                    Employee    : "",
                    EmployeeName: "",
                    LinkTransID : "",
                    ProDutyID: "",
                    ProDutyDate: "",
                    ProReasonID: "",
                    ProNotice: "",
                });
                setDataCboEmployees(getUser(data || []));
                setDataProBaseSalary(initProBaseSalary);
                setDataProSalCoefficient(initProSalCoefficient);
                setDataOldForm(initDataOld);
            }
        });
    };

    useEffect(() => {
        loadCboDuty();
        loadCboReason();
        loadCaptions();
        loadComlumns();
        loadCboTranstypes();
        if (mode === "edit") {
            loadForm();
            loadAttachments();
            setIsEdit(true)
        }
        if (mode === "view") {
            loadForm();
            loadAttachments();
            setIsView(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isChanged.current) {
            if(dataForm?.Employee === "" &&
                dataForm?.ProDutyID === "" &&
                dataForm?.ProDutyDate === "" &&
                dataForm?.ProReasonID === "" &&
                dataForm?.ProNotice === "") {
                loadCboEmployees();
            }
        }
        // load CboEmployee khi xet default TransTypeID voi mode add
        if (!setDefaultTransTypeID.current && dataForm?.TransTypeID && mode === "add") {
            if (!setDefaultTransTypeID.current) {
                setDefaultTransTypeID.current = true;
            }
            loadCboEmployees();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataForm?.TransTypeID]);

    useEffect(() => {
        if (isChanged.current && dataCboEmployees.length > 0 && (dataForm?.Employee || dataForm?.Employee === "")) {
            const employee = dataCboEmployees.find(item => item.EmployeeID === dataForm.Employee) || {};
            setData({
                EmployeeName: employee?.EmployeeName ?? "",
                LinkTransID : employee?.LinkTransID ?? "",
                TempDutyID : employee?.TempDutyID ?? ""
            });
            setDataByKeyID(employee);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataForm?.Employee]);

    const mergeArrayObjects = (arr1 = [],arr2 = []) => {
        return arr1.map(item => {
            const obj = arr2.find(o => o.EmployeeID === item.EmployeeID);
            return { ...item, ...obj };
        });
    };

    const getUser = (data = []) => {
        if(!data?.length) return false;
        const dataList = data.map(item => {return item.EmployeeID});
        const user = Config.getListUser(dataList);
        return mergeArrayObjects(data, user);
    };

    const setDataByKeyID = (data = {}) => {
        const dataTempBaseSalary     = getDataByKeyID(data, "TempBaseSalary", "BaseSalary") ?? {};
        const dataTempSalCoefficient = getDataByKeyID(data, "TempSalCoefficient", "SalCoefficient") ?? {};
        setDataOldForm({
            ...dataOldForm,
            LinkTransID : data?.LinkTransID ?? "",
            TempDutyID  : data?.TempDutyID ?? "",
            TempDutyName: data?.TempDutyName ?? "", ...dataTempBaseSalary, ...dataTempSalCoefficient
        })
    };

    const getDataByKeyID = (data = {}, nameField = "", keyID = "") => {
        const obj = {};
        for (let [key, value] of Object.entries(data)) {
            let num = key?.slice(-2);
            if (!isNaN(num)) {
                if (key.includes(keyID)) {
                    obj[nameField + num] = value;
                }
            }
        }
        return obj;
    };

    const onClosePopup = (isReload = false) => {
        if (onClose) onClose(isReload);
    };

    const setStateErrorText = (objValue) => {
        if (objValue) {
            setErrorHelperText({
                ...errorHelperText,
                ...objValue
            });
            return Object.keys(objValue).length !== 0;
        } else {
            setErrorHelperText({});
            return false;
        }
    };

    const setErrorHelperText = (obj) => {
        _setErrorHelperText({...errorHelperText, ...obj});
    };

    const setData = (obj) => {
        setDataForm({...dataForm, ...obj});
    };

    const handleProBaseSalary = (key, e) => {
        const value = e?.value ?? e?.target?.value ?? "";
        setDataProBaseSalary({
            ...dataProBaseSalary,
            [key]: value
        });
    };

    const handleProSalCoefficient = (key, e) => {
        const value = e?.value ?? e?.target?.value ?? "";
        setDataProSalCoefficient({
            ...dataProSalCoefficient,
            [key]: value
        });
    };

    const handleChange = (key, e) => {
        if (!key || !e) return false;
        if (!isChanged.current) {
            isChanged.current = true;
        }
        const value = e?.value ?? e?.target?.value ?? "";
        if (errorHelperText[key] && value) setErrorHelperText({[key]: ""});
        if(key === "TransTypeID") {
            if(dataForm?.Employee !== "" ||
                dataForm?.ProDutyID !== "" ||
                dataForm?.ProDutyDate !== "" ||
                dataForm?.ProReasonID !== "" ||
                dataForm?.ProNotice !== "") {
                if(value) dtTransTypeID.current = value;
                setData({[key]: value});
                Config.popup.show(
                    "YES_NO",
                    Config.lang("Tat_ca_thao_tac_nhap_lieu_truoc_do_se_bi_mat,ban_co_muon_tiep_tuc_khong?"),
                    () => {
                        isChanged.current = false;
                        loadDataTransTypeID(value);
                    }, () => {
                        setData({[key]: dtTransTypeID.current});
                    }
                );
            } else {
                setData({[key]: value});
            }
        } else {
            setData({[key]: value});
        }
    };

    const onChangeAttachments = (e) => {
        attachments.current = e?.allNewAttached || [];
        if (e?.removedAttached?.length > 0) {
            deletedFile.current = [...e.removedAttached];
            const _arrRemove    = deletedFile.current.map((d) => d.AttachmentID);
            const dataNew       = [...dataOldAttachments].filter(att => _arrRemove.indexOf(att.AttachmentID) < 0);
            setDataOldAttachments(dataNew);
        }
    };

    const _getAttachments = (file) => {
        const dataFile        = file?.data?.paths || [];
        const listAttachments = Config.helpers.getFileInfomations(dataFile);
        let arrAttachment     = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL     : att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt : att.fileName ? att.fileName.split('.').pop() : ""
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    const _uploadFile = (files, isAsync, cb) => {
        if (isAsync) {
            return CDN.uploadFileSync(files);
        } else {
            return CDN.uploadFile(files, null, cb);
        }
    };

    const onSave = async () => {
        let validateRules = [
            {
                name : "TransTypeID",
                rules: ["isRequired"],
                value: dataForm.TransTypeID || ""
            },
            {
                name : "Employee",
                rules: ["isRequired"],
                value: dataForm.Employee || ""
            },
            {
                name : "ProDutyDate",
                rules: ["isRequired"],
                value: dataForm.ProDutyDate || ""
            },

        ];

        if (dataForm?.TransTypeID !== "0013") {
            validateRules.push({
                name : "ProDutyID",
                rules: ["isRequired"],
                value: dataForm.ProDutyID || ""
            },)
        }

        const validateForm = MForm.formValidation(validateRules);
        if (Object.keys(validateForm).length > 0) {
            setStateErrorText(validateForm);
            if (setStateErrorText(validateForm)) {
                return false;
            }
        }

        const modeSave          = mode === "edit" ? "edit" : "save";
        const dataResUploadFile = await _uploadFile(attachments.current, true);
        const arrAttachments    = _getAttachments(dataResUploadFile);
        let params              = {
            Language     : Config.language || 84,
            FormID       : "W09F2291",
            EmployeeID   : dataForm?.Employee ?? "",
            ProDutyID    : dataForm?.TransTypeID !== "0013" ? (dataForm?.ProDutyID ?? "") : "",
            ProReasonID  : dataForm?.ProReasonID ?? "",
            TransTypeID  : dataForm?.TransTypeID ?? "",
            TempDutyID   : dataForm?.TempDutyID ?? "",
            LinkTransID  : dataForm?.LinkTransID ?? "",
            ProDutyDate  : dataForm?.ProDutyDate ?? "",
            ProNotice    : dataForm?.ProNotice ?? "",
            IsSalAdjust  : +isSalAdjust,
            arrAttachment: JSON.stringify(arrAttachments),
        };

        getColumns.forEach(item => {
            if (!item.Disabled) {
                let num       = item?.Code?.slice(-2) ?? "";
                let nameField = "ProBaseSalary" + num;
                params[nameField]  = !isSalAdjust ? 0 : dataProBaseSalary[nameField] ? dataProBaseSalary[nameField] : 0;
            }
        });

        getCaptions.forEach(item => {
            if (!item.Disabled) {
                let num       = item?.Code?.slice(-2) ?? "";
                let nameField = "ProSalCoefficient" + num;
                params[nameField]  = !isSalAdjust ? 0 : dataProSalCoefficient[nameField] ? dataProSalCoefficient[nameField] : 0;
            }
        });

        if (mode === "edit") params = {
            ...params,
            ProTransID: dataForm?.ProTransID || ""
        };
        setIsSaved(true);
        props.w09f2291Actions[modeSave](params, async (error, data) => {
            setIsSaved(false);
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }

            if (data.Status === 1) {
                const message = data.Message || Config.lang("Loi_chua_xac_dinh");
                if (data?.MsgAsk === 0 || !data.hasOwnProperty("MsgAsk")) {
                    Config.popup.show('INFO', message);
                    return false;
                }
                if (data?.MsgAsk === 1) {
                    params = {
                        ...params,
                        code: data?.code || ""
                    };
                    Config.popup.show(
                        "YES_NO", message,
                        () => {
                            confirmSave(params);
                        }
                    );
                    return false;
                }
            }

            if (data.Status === 0) {
                Config.notify.show('success', Config.lang("Luu_thanh_cong"), 2000);
                onClosePopup(true);
                if (mode === "add") {
                    params = {
                        ...params,
                        ProTransID: data?.ProTransID || ""
                    };
                    await saveHistory({...params});

                }
                if (mode === "edit") {
                    await saveHistory({...params});
                }
            }
        });
    };

    const confirmSave = (params = {}) => {
        const modeConfirm = mode === "edit" ? "confirmEdit" : "confirmSave";
        setIsSaved(true);
        props.w09f2291Actions[modeConfirm](params, async (error, data) => {
            setIsSaved(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }

            if (data.Status === 0) {
                Config.notify.show('success', Config.lang("Luu_thanh_cong"), 2000);
                onClosePopup(true);
                if (mode === "add") {
                    params = {
                        ...params,
                        ProTransID: data?.ProTransID || ""
                    };
                    await saveHistory({...params});

                }
                if (mode === "edit") {
                    await saveHistory({...params});
                }
            }
        });
    };

    const saveHistory = async (data) => {
        if (!data) return false;
        const ProTransID  = data?.ProTransID;
        const transTypeID = dataCboTranstype?.find(item => item.TransTypeID === dataForm?.TransTypeID) ?? "";
        const proDutyID   = dataForm?.TransTypeID !== "0013" ? getCboDuty?.find(item => item.DutyID === dataForm?.ProDutyID) : "";
        const proReasonID = getCboReasons?.find(item => item.ReasonID === dataForm?.ProReasonID) ?? "";
        if (ProTransID) {
            const _dataField = {};
            const _captions = {};

            getColumns.forEach(item => {
                if (!item.Disabled) {
                    let num       = item?.Code?.slice(-2) ?? "";
                    let nameField = "ProBaseSalary" + num;
                    _dataField[nameField]  = data[nameField] ? data[nameField] : 0;
                    _captions[nameField]  = `Luong_co_ban_%${num}%`;
                }
            });

            getCaptions.forEach(item => {
                if (!item.Disabled) {
                    let num       = item?.Code?.slice(-2) ?? "";
                    let nameField = "ProSalCoefficient" + num;
                    _dataField[nameField]  = data[nameField] ? data[nameField] : 0;
                    _captions[nameField]  = `He_so_luong_%${num}%`;
                }
            });

            // data Content
            let _data = {
                TransTypeID        : dataForm?.TransTypeID ?? "",
                TransTypeName      : transTypeID?.TransTypeName ?? "",
                EmployeeID         : dataForm?.Employee ?? "",
                EmployeeName       : dataForm?.EmployeeName ?? "",
                ProDutyDate        : dataForm?.ProDutyDate ?? "",
                ProDutyID          : dataForm?.ProDutyID ?? "",
                ProDutyName        : proDutyID?.DutyName ?? "",
                ProReasonID        : dataForm?.ProReasonID ?? "",
                ProReasonName      : proReasonID?.ReasonName ?? "",
                ProNotice          : dataForm?.ProNotice,
                IsSalAdjust        : +isSalAdjust,
                ...(_dataField ?? {}),
            };


            //name Content
            const captions = {
                TransTypeID        : "Ma_nghiep_vu",
                TransTypeName      : "Ten_nghiep_vu",
                EmployeeID         : "Ma_nhan_vien",
                EmployeeName       : "Ten_nhan_vien",
                ProDutyDate        : "Ngay_hieu_luc",
                ProDutyID          : "Ma_chuc_vu_moi",
                ProDutyName        : "Ten_chuc_vu_moi",
                ProReasonID        : "Ma_ly_do",
                ProReasonName      : "Ten_ly_do",
                ProNotice          : "Ghi_chu",
                IsSalAdjust        : "Dieu_chinh_luong",
                ...(_captions ?? {}),
            };
            const _dataOld = {...dataOld.current};
            Object.keys(dataOld.current).forEach(item => {
                let num = item?.slice(-2);
                if (!isNaN(num)) {
                    if (item.includes("ProBaseSalary" + num)) {
                        _dataOld[item] = dataOld.current[item];
                    }
                    if (item.includes("ProSalCoefficient" + num)) {
                        _dataOld[item] = dataOld.current[item];
                    }
                }
            });
            const options = {
                data           : _data,
                captions       : captions,
                dataCompare    : _dataOld,
                action         : mode === "edit" ? 1 : 0,
                ModuleID       : "D09",
                TransID        : ProTransID,
                TransactionID  : "W09F2290",
                TransactionName: "Lập đề xuất miễn nhiệm",
            };

            const history = new History(options); //Init history
            if (history.get()?.length > 0) {
                await history.save();
            }
        } else {
            Config.notify.show("error", Config.lang("Luu_lich_su_khong_thanh_cong"), 2000);
        }

    };

    return (
        <>
            <Modal
                open={open}
                pressESCToClose={false}
                moveable={false}
                dragAnyWhere={false}
                moveOutScreen={false}
                width={"80%"}
                onClose={() => onClosePopup(false)}
            >

                <ModalHeader title={Config.lang("Lap_de_xuat_mien_nhiem")}/>
                <ModalBody>
                    <FormGroup>
                        <Row>
                            <Col xs={12} sm={12} md={6}>
                                <Dropdown
                                    error={errorHelperText && errorHelperText["TransTypeID"]}
                                    label={Config.lang("Nghiep_vu")}
                                    dataSource={dataCboTranstype}
                                    displayExpr={"TransTypeName"}
                                    valueExpr={"TransTypeID"}
                                    clearAble={true}
                                    value={dataForm.TransTypeID}
                                    required={true}
                                    loading={loadingCboTranstype}
                                    readOnly={isEdit}
                                    disabled={isView}
                                    onChange={e => handleChange("TransTypeID", e)}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={6}>
                                <Dropdown
                                    error={errorHelperText && errorHelperText["Employee"]}
                                    readOnly={isEdit}
                                    disabled={isView}
                                    loading={loadingCboEmployees || loadingCboTranstypeID}
                                    label={Config.lang("Nhan_vien")}
                                    dataSource={dataCboEmployees}
                                    displayExpr={"{EmployeeID} - {EmployeeName}"}
                                    valueExpr={"EmployeeID"}
                                    iconExpr={{key: 'UserPictureURL', prefix: Config.getCDNPath()}}
                                    clearAble={true}
                                    allowSearch={true}
                                    required={true}
                                    value={dataForm.Employee}
                                    onChange={e => handleChange("Employee", e)}
                                    renderItem={e => {
                                        if (!e) return null;
                                        const {data} = e;
                                        return data.EmployeeID + " - " + data.EmployeeName;
                                    }}
                                    renderSelectedItem={e => {
                                        if (!e) return null;
                                        const {data} = e;
                                        return (
                                            <div className={"display_row align-center"}>
                                                <Avatar
                                                    clearAble={false}
                                                    width={24}
                                                    height={24}
                                                    readOnly
                                                    src={data?.UserPictureURL ?? ''}
                                                    hoverAble={false}
                                                />
                                                <Typography
                                                    hoverTooltip
                                                    className={"mgl5"}
                                                    type={"p1"}
                                                    lineClamp={1}
                                                >
                                                    {`${data?.EmployeeID} - ${data?.EmployeeName}`}
                                                </Typography>
                                            </div>
                                        )
                                    }}
                                />
                            </Col>
                            {dataForm?.TransTypeID !== "0013" ?
                                <Col xs={12} sm={12} md={6}>
                                    <Dropdown
                                        error={errorHelperText && errorHelperText["ProDutyID"]}
                                        loading={loadingCboDuty}
                                        label={Config.lang("Chuc_vu_moi")}
                                        dataSource={getCboDuty}
                                        displayExpr={"DutyName"}
                                        valueExpr={"DutyID"}
                                        clearAble={true}
                                        value={dataForm.ProDutyID}
                                        required={true}
                                        disabled={isView}
                                        onChange={e => handleChange("ProDutyID", e)}
                                    />
                                </Col>
                                : ""}

                            <Col xs={12} sm={12} md={6}>
                                <DatePicker
                                    error={errorHelperText && errorHelperText["ProDutyDate"]}
                                    required
                                    clearAble
                                    label={Config.lang("Ngay_hieu_luc")}
                                    viewType={"underlined"}
                                    placeholder={"dd/mm/yyyy"}
                                    displayFormat={"DD/MM/YYYY"}
                                    value={dataForm.ProDutyDate}
                                    disabled={isView}
                                    onChange={e => handleChange("ProDutyDate", e)}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Dropdown
                                    label={Config.lang("Ly_do")}
                                    dataSource={getCboReasons}
                                    loading={loadingCboReason}
                                    displayExpr={"ReasonName"}
                                    valueExpr={"ReasonID"}
                                    value={dataForm.ProReasonID}
                                    clearAble={true}
                                    disabled={isView}
                                    onChange={e => handleChange("ProReasonID", e)}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <TextInput
                                    label={Config.lang("Ghi_chu")}
                                    value={dataForm.ProNotice}
                                    disabled={isView}
                                    onChange={e => handleChange("ProNotice", e)}
                                />
                            </Col>
                            {isPermission > 1 ?
                                <Col xs={12}>
                                    <Typography type={"h4"} className={"mgt15 mgb15"}>
                                        {Config.lang("Thong_tin_luong")}
                                    </Typography>
                                </Col>
                            : "" }
                        </Row>
                        {isPermission > 1 ?
                            <div className={"display_row mgb10"}>
                                <Checkbox
                                    checked={isSalAdjust}
                                    disabled={isView}
                                    label={Config.lang("Dieu_chinh_luong")}
                                    onChange={(e) => setIsSalAdjust(e.value)}
                                />
                            </div>
                        : "" }

                        {isPermission > 1 && isSalAdjust ?
                            <>
                                <Row>
                                    {getColumns.map((item, idx) => {
                                        if (item?.Disabled) return null;
                                        let num       = item?.Code?.slice(-2);
                                        let nameField = "ProBaseSalary" + num;
                                        return (
                                            <Col key={idx} xs={12} sm={12} md={6}>
                                                <MoneyInput
                                                    label={`${item?.CaptionName ?? ""} ${item?.CaptionName ? Config.lang("moi") : ""}`}
                                                    thousandSeparator={','}
                                                    value={dataProBaseSalary[nameField]}
                                                    decimalDigit={item?.Decimals ?? 0}
                                                    disabledNegative
                                                    disabled={isView}
                                                    onChange={e => handleProBaseSalary(nameField, e)}
                                                />
                                            </Col>
                                        )
                                    })}
                                    {getCaptions.map((item, idx) => {
                                        if (item?.Disabled) return null;
                                        let num       = item?.Code?.slice(-2);
                                        let nameField = "ProSalCoefficient" + num;
                                        return (
                                            <Col key={idx} xs={12} sm={12} md={6}>
                                                <MoneyInput
                                                    label={`${item?.CaptionName ?? ""} ${item?.CaptionName ? Config.lang("moi") : ""}`}
                                                    thousandSeparator={','}
                                                    decimalDigit={item?.Decimals ?? 0}
                                                    disabledNegative
                                                    value={dataProSalCoefficient[nameField]}
                                                    disabled={isView}
                                                    onChange={e => handleProSalCoefficient(nameField, e)}
                                                />
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </>
                            : ""}
                    </FormGroup>

                    <FormGroup>
                        <Accordion expand={false}>
                            <AccordionSummary>
                                {Config.lang("Thong_tin_cu")}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Row>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <span style={{fontWeight: 600}}>{Config.lang("Chuc_vu")}:</span>&nbsp;
                                        {dataOldForm["TempDutyName"] ?? ""}
                                    </Col>
                                    {isPermission > 0 && getColumns.map((item, idx) => {
                                        if (item?.Disabled) return null;
                                        const num       = item?.Code?.slice(-2);
                                        const nameField = "TempBaseSalary" + num;
                                        return (
                                            <Col key={idx} xs={12} sm={12} md={6} lg={6}>
                                                <span style={{fontWeight: 600}}>{item?.CaptionName ?? ""}:</span>&nbsp;
                                                {dataOldForm[nameField] ? new Intl.NumberFormat().format(dataOldForm[nameField]) : 0}
                                            </Col>
                                        )
                                    })}

                                    {isPermission > 0 && getCaptions.map((item, idx) => {
                                        if (item?.Disabled) return null;
                                        const num       = item?.Code?.slice(-2);
                                        const nameField = "TempSalCoefficient" + num;
                                        return (
                                            <Col key={idx} xs={12} sm={12} md={6} lg={6}>
                                                <span style={{fontWeight: 600}}>{item?.CaptionName ?? ""}:</span>&nbsp;
                                                {dataOldForm[nameField] ? new Intl.NumberFormat().format(dataOldForm[nameField]) : 0}
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </AccordionDetails>
                        </Accordion>
                    </FormGroup>
                    <FormGroup>
                        <Accordion expand={false}>
                            <AccordionSummary>
                                {Config.lang("Dinh_kem")}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Attachment
                                    maxFile={5}
                                    maxSize={"5mb"}
                                    multiple
                                    unitSize={"mb"}
                                    disabled={loadingAttachments || loadingDataForm || (mode === "edit" && !!(Number(data?.AppStatusID))) || isView}
                                    data={dataOldAttachments}
                                    onChange={(e) => {
                                        onChangeAttachments(e)
                                    }}
                                    uploadErrorInfo={{
                                        existingFile: Config.lang('File_da_duoc_dinh_kem'),
                                        maxFile     : Config.lang('File_vuot_qua_so_luong_cho_phep'),
                                        maxSize     : Config.lang('File_vuot_qua_dung_luong_cho_phep'),
                                        fileType    : Config.lang("File_khong_dung_dinh_dang"),
                                    }}

                                />
                            </AccordionDetails>
                        </Accordion>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button
                        text={Config.lang("Luu")}
                        viewType={"filled"}
                        color={"info"}
                        disabled={loadingDataForm || (mode === "edit" && !!(Number(data?.AppStatusID))) || isView || isSaved}
                        startIcon={"save"}
                        size={"large"}
                        onClick={onSave}
                    />
                </ModalFooter>
            </Modal>
        </>
    );
};


W09F2291.propTypes = {
    data         : PropTypes.object,
    mode         : PropTypes.string,
    open         : PropTypes.bool,
    onClose      : PropTypes.func,
    getCboDuty   : PropTypes.array,
    getCboReasons: PropTypes.array,
    getColumns   : PropTypes.array,
    getCaptions   : PropTypes.array,

};

W09F2291.defaultProps = {
    data         : {},
    mode         : "",
    open         : false,
    onClose      : null,
    getCboDuty   : [],
    getCboReasons: [],
    getColumns   : [],
    getCaptions   : [],
};

export default compose(
    connect((state) => ({
            getCboDuty   : state.general.getCboDuty2,
            getCboReasons: state.W09F2291.getCboReasons,
            getColumns   : state.W09F2291.getColumns,
            getCaptions  : state.W09F2291.getCaptions,
        }),
        (dispatch) => ({
            w09f2291Actions: bindActionCreators(W09F2291Actions, dispatch),
            generalActions : bindActionCreators(generalActions, dispatch),
        })
    ),
)(W09F2291);
