/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2/3/2021
 * @Example
 */

import React, {useEffect}            from "react";
import {connect}                     from "react-redux";
import {bindActionCreators, compose} from "redux";
import PropTypes                     from "prop-types";
import {FormGroup}                   from "react-bootstrap";
import {Checkbox}                    from "../../../common/form-material";
import * as W09F3005Actions          from "../../../../redux/W0X/W09F3005/W09F3005_actions";
import * as _                        from "lodash";
import Config                        from "../../../../config";

const W09F3005Tab2 = React.memo((props) => {

    const {disabled, dataForm, dataProcess} = props;
    const {ProcessID}                       = dataForm || {};

    const loadProcess = () => {
        props.W09F3005Actions.loadProcess({Language: Config.language || "84"}, (error) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    useEffect(loadProcess, []);

    const onHandleChange = (key, e) => {
        const checked    = _.get(e, "target.checked", false);
        let storeCheck   = [...ProcessID];
        const {onChange} = props;
        if (checked) {
            if (!storeCheck.includes(key)) storeCheck.push(key);
        } else {
            storeCheck = storeCheck.filter(c => c !== key);
        }
        if (onChange) onChange("ProcessID", storeCheck);
    };

    return (
        <FormGroup style={{
            minHeight: 200,
            marginTop: 15
        }}>
            {dataProcess && dataProcess.map((item, idx) => {
                const checked = ProcessID.includes(item.ProcessID);
                return (
                    <div key={idx}>
                        <Checkbox label={item.ProcessName}
                                  disabled={disabled}
                                  checked={checked}
                                  color={"primary"}
                                  onChange={(e) => onHandleChange(item.ProcessID, e)}
                        />
                    </div>
                );
            })}
        </FormGroup>
    );
}, (prevProps, nextProps) => {
    return JSON.stringify(prevProps.dataForm) === JSON.stringify(nextProps.dataForm)
        && prevProps.disabled === nextProps.disabled
        && JSON.stringify(prevProps.dataProcess) === JSON.stringify(nextProps.dataProcess)
});

W09F3005Tab2.propTypes = {
    disabled: PropTypes.bool,
    dataForm: PropTypes.object,

    onChange: PropTypes.func
};

export default compose(connect(state => ({
    dataProcess: state.W09F3005.dataProcess,
}), dispatch => ({
    W09F3005Actions: bindActionCreators(W09F3005Actions, dispatch),
})))(W09F3005Tab2);
