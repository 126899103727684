/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 20/12/2021
 * @Example
 */

import { Column } from 'devextreme-react/data-grid';
import {
    Avatar,
    Button,
    ButtonIcon,
    DateRangePicker,
    Dropdown,
    ModalSample,
    Slider,
    SliderItem,
    Tooltip,
    Typography,
} from 'diginet-core-ui/components';
import Icon from 'diginet-core-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { browserHistory } from 'react-router';
import Config from '../../../../config';
import * as generalActions from '../../../../redux/general/general_actions';
import * as W09F2310Actions from '../../../../redux/W0X/W09F2310/W09F2310_actions';
import ActionToolbar from '../../../common/toolbar/action-toolbar';
import Filter from '../../../filter/filter';
import GridActionBar from '../../../grid-container/grid-actionbar';
import GridContainer from '../../../grid-container/grid-container';
import History from '../../../libs/history';
import { useDateRangePicker, useDropdown } from './utils/customHook';

const W09F2310 = () => {
    const [iPermission, setPermission] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingSignature, setLoadingSignature] = useState(false);
    const [loadingCboEmployees, setLoadingCboEmployees] = useState(false);
    const [dataCboEmployees, setDataCboEmployees] = useState({
        rows: [],
        total: 0,
    });
    const [isShowFile, setIsShowFile] = useState(false);

    const { value: dateRange, bind: bindDateRange } = useDateRangePicker(null);
    const { value: TransTypeID, setValue: setTransTypeID, bind: bindTransTypeID } = useDropdown('001');
    const { value: SignStatusID, bind: bindSignStatusID } = useDropdown('0');
    const { value: EmployeeID, bind: bindEmployeeID } = useDropdown('');
    const {
        value: TypeOfSignature,
        setValue: setTypeOfSignature,
        bind: bindTypeOfSignature,
    } = useDropdown(TransTypeID, (val) => {
        setTransTypeID(val);
        getData(val);
    });

    const [tempSignStatusID, setTempSignStatusID] = useState(SignStatusID);

    const gridRef = useRef(null);
    const paramsData = useRef({
        skip: 0,
        limit: 30,
        isPage: 0,
        listPerPage: [30, 40, 50, 60],
    });
    const transTypeDS = useRef([
        {
            TransTypeID: '001',
            TransTypeName: Config.lang('Hop_dong_lao_dong_L'),
        },
        {
            TransTypeID: '002',
            TransTypeName: Config.lang('Phu_luc_HDLD'),
        },
    ]);
    const currentData = useRef(null);

    const dispatch = useDispatch();
    const dataGrid = useSelector((state) => state?.W09F2310?.getData ?? { rows: [], total: 0 });

    const paramsFilterCboEmployees = {
        skip: 0,
        limit: 10,
        search: '',
    };

    const getPermission = () => {
        dispatch(
            generalActions.getPermission('W09F2310', (isPer) => {
                setPermission(isPer);
            })
        );
    };

    const getData = (typeOfSignature) => {
        const params = {
            CompanyID: Config.getDatabaseName(),
            DateFrom: dateRange?.[0] ?? null,
            DateTo: dateRange?.[1] ?? null,
            TypeOfSignature: typeOfSignature ?? TypeOfSignature ?? '',
            SignStatusID,
            EmployeeID,
            skip: paramsData.current?.skip ?? 0,
            limit: paramsData.current?.limit ?? 30,
        };
        setLoading(true);
        dispatch(
            W09F2310Actions.getData(params, (err) => {
                setLoading(false);
                if (err) return Config.popup.show('ERROR', err);
            })
        );
    };

    useEffect(() => {
        getPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (iPermission < 1) return;
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iPermission]);

    const loadCboEmployees = (isReset) => {
        const param = {
            Type: 'EmployeeID',
            FormID: 'W09F2310',
            Language: Config.language || '84',
            ...paramsFilterCboEmployees,
        };
        setLoadingCboEmployees(true);
        dispatch(
            generalActions.getCboEmployees(param, (err, data) => {
                setLoadingCboEmployees(false);
                if (err) return Config.popup.show('ERROR', err);
                if (data) {
                    const rows = data?.rows ?? data;
                    const total = data?.total ?? data?.length;
                    setDataCboEmployees({
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total,
                    });
                }
            })
        );
    };

    const onLoadCboFilter = () => {
        loadCboEmployees();
    };

    const renderFilter = () => {
        return (
            <Filter
                isUseDDCore
                placeholder={Config.lang('Noi_dung_can_tim_kiem')}
                openOnFieldClick={true}
                onOpenLoaded={onLoadCboFilter}
                onClosed={() => {
                    if (TransTypeID !== TypeOfSignature) {
                        setTransTypeID(TypeOfSignature ?? '');
                    }
                }}
                renderFilter={() => {
                    return (
                        <>
                            <DateRangePicker
                                clearAble
                                controls
                                value={dateRange}
                                label={Config.lang('Ngay_chuyen_tu_den')}
                                {...bindDateRange}
                                placeholder={'dd/mm/yyyy - dd/mm/yyyy'}
                                returnFormat={'YYYY-MM-DD'}
                                viewType={'outlined'}
                            />
                            <Dropdown
                                valueExpr={'TransTypeID'}
                                keyExpr={'TransTypeName'}
                                displayExpr={'TransTypeName'}
                                placeholder={Config.lang('Chon')}
                                label={Config.lang('Nghiep_vu')}
                                {...bindTransTypeID}
                                dataSource={transTypeDS.current}
                                value={TransTypeID ?? null}
                                viewType={'outlined'}
                            />
                            <Dropdown
                                clearAble
                                valueExpr={'SignStatusID'}
                                keyExpr={'SignStatusName'}
                                displayExpr={'SignStatusName'}
                                placeholder={Config.lang('Chon')}
                                label={Config.lang('Trang_thai')}
                                {...bindSignStatusID}
                                dataSource={[
                                    {
                                        SignStatusID: '0',
                                        SignStatusName: Config.lang('Chua_ky'),
                                    },
                                    {
                                        SignStatusID: '1',
                                        SignStatusName: Config.lang('Da_ky'),
                                    },
                                ]}
                                value={SignStatusID ?? null}
                                viewType={'outlined'}
                            />
                            <Dropdown
                                allowSearch
                                clearAble
                                dataSource={dataCboEmployees.rows}
                                total={dataCboEmployees.total}
                                skip={paramsFilterCboEmployees.skip}
                                limit={paramsFilterCboEmployees.limit}
                                displayExpr={'{EmployeeID} - {EmployeeName}'}
                                keyExpr={'EmployeeName'}
                                valueExpr={'EmployeeID'}
                                placeholder={Config.lang('Chon')}
                                label={Config.lang('Nhan_vien')}
                                value={EmployeeID ?? null}
                                {...bindEmployeeID}
                                viewType={'outlined'}
                                loading={loadingCboEmployees}
                                onInput={(e) => {
                                    paramsFilterCboEmployees.search = e?.target?.value ?? '';
                                    paramsFilterCboEmployees.skip = 0;
                                    loadCboEmployees(true);
                                }}
                                onLoadMore={(e) => {
                                    paramsFilterCboEmployees.skip = e?.skip ?? 0;
                                    paramsFilterCboEmployees.limit = e?.limit ?? 10;
                                    loadCboEmployees();
                                }}
                            />
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    text={Config.lang('Tim_kiem')}
                                    viewType={'outlined'}
                                    color={'primary'}
                                    onClick={onSearch}
                                    startIcon={'Search'}
                                />
                            </div>
                        </>
                    );
                }}
            />
        );
    };

    const onSearch = () => {
        setTempSignStatusID(SignStatusID);
        setTypeOfSignature(TransTypeID);
        paramsData.current = {
            skip: 0,
            limit: 30,
            isPage: 0,
        };
        getData(TransTypeID);
    };

    const onChangePage = (page) => {
        paramsData.current.isPage = page;
        paramsData.current.skip = page * (paramsData.current?.limit ?? 30);
        getData();
    };

    const onChangePerPage = (perPage) => {
        paramsData.current.skip = 0;
        paramsData.current.limit = perPage;
        getData();
    };

    const renderAvatar = (e) => {
        const { data } = e;
        const user = Config.getUser({ EmployeeID: data.EmployeeID });
        let info = {};
        if (user?.EmployeeID && user?.EmployeeName) {
            info[Config.lang('Nhan_vien')] = `${user.EmployeeID} - ${user.EmployeeName}`;
        }
        if (user?.DepartmentName) {
            info[Config.lang('Phong_ban')] = user.DepartmentName;
        }
        if (user?.ProjectName) {
            info[Config.lang('Du_an')] = user.ProjectName;
        }
        if (user?.DutyName) {
            info[Config.lang('Chuc_vu')] = user.DutyName;
        }
        if (user?.DateJoined) {
            info[Config.lang('Ngay_vao_lam')] = Config.convertDate(user?.DateJoined, '', 'L');
        }
        return (
            <Avatar
                clearAble={false}
                width={32}
                height={32}
                readOnly
                data={info}
                hoverAble
                src={user?.UserPictureURL || ''}
                onClick={() => {
                    browserHistory.push({
                        pathname: Config.getRootPath() + 'W09F2000',
                        state: { EmployeeID: data.EmployeeID },
                    });
                }}
            />
        );
    };

    const renderEmployeeName = (e) => {
        const { data } = e;
        const user = Config.getUser({ EmployeeID: data.EmployeeID });
        return (
            <Tooltip
                arrow
                title={
                    <div>
                        {user?.DepartmentName && (
                            <Typography type={'p2'} color={Config?.coreTheme?.colors?.white ?? '#fff'}>
                                {Config.lang('Phong_ban')}: {user.DepartmentName}
                            </Typography>
                        )}
                        {user?.ProjectName && (
                            <Typography type={'p2'} color={Config?.coreTheme?.colors?.white ?? '#fff'}>
                                {Config.lang('Du_an')}: {user.ProjectName}
                            </Typography>
                        )}
                        {user?.DutyName && (
                            <Typography type={'p2'} color={Config?.coreTheme?.colors?.white ?? '#fff'}>
                                {Config.lang('Chuc_vu')}: {user.DutyName}
                            </Typography>
                        )}
                        {user?.DateJoined && (
                            <Typography type={'p2'} color={Config?.coreTheme?.colors?.white ?? '#fff'}>
                                {Config.lang('Ngay_vao_lam')}: {Config.convertDate(user.DateJoined, '', 'L')}
                            </Typography>
                        )}
                    </div>
                }
            >
                <Typography>{e?.value ?? ''}</Typography>
            </Tooltip>
        );
    };

    const renderSignStatus = (e) => {
        return (
            <div className={'display_row'} style={{ alignItems: 'flex-end' }}>
                <Icon name={e?.data?.SignStatusID ? 'Approval' : 'HourGlass'} color={e?.data?.SignStatusID ? 'success' : 'warning'} />
                <Typography color={e?.data?.SignStatusID ? 'success' : 'warning'}>{e?.data?.SignStatusName ?? ''}</Typography>
            </div>
        );
    };

    const renderAction = (e) => {
        return (
            <GridActionBar>
                {e?.data?.SignStatusID ? (
                    <ButtonIcon
                        circular
                        disabled={e?.data?.SignStatusID !== 1}
                        name={'Download'}
                        viewType={'text'}
                        onClick={() => {
                            const link = document.createElement('a');
                            link.target = '_blank';
                            link.style.display = 'none';
                            link.href = Config.getCDNPath() + e?.data?.DigitalURL;
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }}
                    />
                ) : (
                    <ButtonIcon
                        circular
                        disabled={e?.data?.SignStatusID !== 0}
                        name={'PaperFilled'}
                        viewType={'text'}
                        onClick={() => {
                            const params = {
                                EmployeeID: e?.data?.EmployeeID ?? '',
                                ContractID: e?.data?.ContractID ?? '',
                                TemplateURL: e?.data?.TemplateURL ?? '',
                                TypeOfSignature: TypeOfSignature ?? '',
                            };
                            setLoading(true);
                            dispatch(
                                W09F2310Actions.viewReport(params, (err, dt) => {
                                    setLoading(false);
                                    if (err) return Config.popup.show('ERROR', err);
                                    if (dt) {
                                        currentData.current = dt ?? null;
                                        setIsShowFile(true);
                                    }
                                })
                            );
                        }}
                    />
                )}
            </GridActionBar>
        );
    };

    const onSignature = async (mode) => {
        if (typeof mode === undefined) return;
        const data = gridRef.current?.instance?.getSelectedRowsData() ?? [];
        if (data?.length === 0) {
            Config.popup.show('INFO', Config.lang('Ban_vui_long_chon_du_lieu_tren_luoi'));
            return;
        }
        if (mode && data?.some((row) => !row.SignStatusID)) {
            Config.popup.show('INFO', Config.lang('Ton_tai_nhan_vien_chua_ky_HDLD,_ban_vui_long_chon_lai_du_lieu.'));
            return;
        }
        if (!mode && data?.some((row) => row.SignStatusID)) {
            Config.popup.show('INFO', Config.lang('Ton_tai_nhan_vien_da_ky_HDLD,_ban_vui_long_chon_lai_du_lieu.'));
            return;
        }
        if (!mode && data?.some((row) => !row.ContractTemplate || !row.TemplateURL)) {
            Config.popup.show(
                'INFO',
                Config.lang(
                    TypeOfSignature === '001'
                        ? 'Ton_tai_nhan_vien_chua_co_mau_HDLD,_ban_vui_long_chon_lai_du_lieu.'
                        : 'Ton_tai_nhan_vien_chua_co_mau_phu_luc_HDLD,_ban_vui_long_chon_lai_du_lieu.'
                )
            );
            return;
        }

        setLoadingSignature(true);
        Promise.all(
            data.map((item) => {
                return new Promise((resolve, reject) => {
                    const params = {
                        ...item,
                        TransIDGrid: item?.TransID ?? '',
                        TypeOfSignature,
                    };

                    dispatch(
                        W09F2310Actions[mode ? 'cancelSignature' : 'signature'](params, (err, dt) => {
                            if (err) {
                                resolve({ error: err, item: item });
                            }
                            if (dt) {
                                saveHistory(item, mode);

                                gridRef.current && gridRef.current.instance.deselectRows(item);
                                const dataSource = gridRef.current.instance.getDataSource();
                                dataSource.items().forEach((i) => {
                                    if (i.TransID === item.TransID) {
                                        i.SignStatusID = +!mode;
                                        i.SignStatusName = Config.lang(`${mode ? 'Chua' : 'Da'}_ky`);
                                        if (dt.signedURL) {
                                            i.DigitalURL = dt.signedURL;
                                        }
                                    }
                                });
                                dataSource.load();
                                resolve({ item: item, data: dt });
                            }
                        })
                    );
                });
            })
        )
            .then((res) => {
                setLoadingSignature(false);
                const errorMessage = res
                    .map((i) => {
                        return i?.error
                            ? `${i?.item?.EmployeeID} - ${i?.item?.EmployeeName} - ${i?.error?.message ?? Config.lang('Loi_chua_xac_dinh')}`
                            : '';
                    })
                    .filter((i) => i)
                    .join('</br>');
                if (errorMessage) {
                    Config.popup.show('ERROR', errorMessage);
                } else {
                    Config.notify.show('success', Config.lang(`${mode ? 'Bo' : 'Da'}_ky_thanh_cong`), 2000);
                    getData();
                }
            })
            .catch(() => {
                setLoadingSignature(false);
            });
    };

    const saveHistory = (data, mode) => {
        if (!data) return;
        if (data?.TransID) {
            const _data = {
                TransTypeID: data?.TransTypeID ?? '',
                TransTypeName: data?.TransTypeName ?? '',
                EmployeeID: data?.EmployeeID ?? '',
                EmployeeName: data?.EmployeeName ?? '',
                TypeOfContract: data?.TypeOfContract ?? '',
                ContractTemplate: data?.ContractTemplate ?? '',
                StartDate: data?.StartDate ?? '',
                EndDate: data?.EndDate ?? '',
                TransferDate: data?.TransferDate ?? '',
                SignStatusID: +!!!mode,
            };

            const captions = {
                TransTypeID: 'Ma_nghiep_vu',
                TransTypeName: 'Ten_nghiep_vu',
                EmployeeID: 'Ma_nhan_vien',
                EmployeeName: 'Ten_nhan_vien',
                TypeOfContract: 'Loai_HDLD',
                ContractTemplate: 'Thong_tin_mau',
                StartDate: 'Ngay_bat_dau_HDLD',
                EndDate: 'Ngay_ket_thuc_HDLD',
                TransferDate: 'Ngay_chuyen',
                SignStatusID: 'Trang_thai',
            };

            const options = {
                data: _data,
                captions: captions,
                action: mode,
                ModuleID: 'D09',
                TransID: data.TransID,
                TransactionID: 'W09F2310',
                TransactionName: `${Config.lang('Ky_so_HDLD')}/${Config.lang('Phu_luc_HDLD')}`,
            };

            if (mode) {
                const _dataOld = {
                    ..._data,
                    SignStatusID: data?.SignStatusID ?? 0,
                };
                options.dataCompare = _dataOld;
            }

            const history = new History(options);
            if (history.get().length > 0) {
                history.save();
            }
        } else {
            return Config.notify.show('error', Config.lang('Luu_lich_su_khong_thanh_cong'), 2000);
        }
    };

    return (
        <>
            {isShowFile && (
                <ModalSample open={isShowFile} title={currentData.current?.fileName} onClose={() => setIsShowFile(false)}>
                    <Slider>
                        <SliderItem url={currentData.current?.URL} />
                    </Slider>
                </ModalSample>
            )}
            <ActionToolbar title={`${Config.lang('Ky_so_HDLD')}/${Config.lang('Phu_luc_HDLD')}`}>
                <div className={'display_row align-center flex-wrap'} style={{ gap: 20 }}>
                    {tempSignStatusID !== '1' && (
                        <Button
                            disabled={iPermission < 2}
                            text={Config.lang('Kys')}
                            viewType={'filled'}
                            color={'primary'}
                            onClick={() => onSignature(0)}
                            startIcon={'Approval'}
                            loading={loading || loadingSignature}
                        />
                    )}
                    {tempSignStatusID === '1' && (
                        <Button
                            disabled={iPermission < 2}
                            text={Config.lang('Bo_ky')}
                            viewType={'filled'}
                            color={'primary'}
                            onClick={() => {
                                Config.popup.show(
                                    'YES_NO',
                                    Config.lang(
                                        TypeOfSignature === '001'
                                            ? 'Cac_nhan_vien_nay_da_duoc_ky_so_HDLD,_ban_co_muon_bo_ky?'
                                            : 'Cac_nhan_vien_nay_da_duoc_ky_so_phu_luc_HDLD,_ban_co_muon_bo_ky?'
                                    ),
                                    () => {
                                        onSignature(1);
                                    }
                                );
                            }}
                            startIcon={'Close'}
                            loading={loading || loadingSignature}
                        />
                    )}
                    <Dropdown
                        valueExpr={'TransTypeID'}
                        keyExpr={'TransTypeName'}
                        displayExpr={'TransTypeName'}
                        placeholder={Config.lang('Chon')}
                        {...bindTypeOfSignature}
                        dataSource={transTypeDS.current}
                        value={TypeOfSignature ?? null}
                        viewType={'underlined'}
                        style={{ margin: 0 }}
                    />
                </div>
            </ActionToolbar>
            <div className={'hidden'}>{renderFilter()}</div>
            <GridContainer
                reference={(ref) => (gridRef.current = ref)}
                style={{ border: 'none', marginTop: 15 }}
                dataSource={dataGrid?.rows ?? []}
                keyExpr={"TransID"}
                showRowLines={true}
                noDataText={Config.lang('No_data')}
                loading={loading}
                hoverStateEnabled={true}
                totalItems={dataGrid?.total ?? 0}
                isPage={paramsData.current.isPage}
                itemPerPage={paramsData.current.limit}
                skipPerPage={paramsData.current.skip}
                listPerPage={paramsData.current.listPerPage}
                typePaging={'remote'}
                height={Config.getHeightGrid() - 52}
                pagerFullScreen={false}
                onChangePage={onChangePage}
                onChangePerPage={onChangePerPage}
                selection={{
                    mode: 'multiple',
                    selectAllMode: 'allPages',
                    showCheckBoxesMode: 'always',
                }}
                filterRow={{
                    visible: true,
                    showOperationChooser: false,
                }}
                allowColumnResizing
            >
                <Column dataField={'IsChoose'} type={'selection'} dataType={'boolean'} width={50} />
                <Column cellRender={renderAvatar} alignment={'center'} minWidth={70} />
                <Column caption={Config.lang('Ma_nhan_vien')} dataField={'EmployeeID'} dataType={'string'} minWidth={90} />
                <Column
                    caption={Config.lang('Ten_nhan_vien')}
                    dataField={'EmployeeName'}
                    dataType={'string'}
                    cellRender={renderEmployeeName}
                    minWidth={120}
                />
                <Column
                    caption={Config.lang(TypeOfSignature === '001' ? 'Loai_HDLD' : 'Loai_phu_lucU')}
                    dataField={'TypeOfContract'}
                    dataType={'string'}
                    minWidth={150}
                />
                <Column caption={Config.lang('Thong_tin_mau')} dataField={'ContractTemplate'} dataType={'string'} minWidth={150} />
                <Column
                    caption={Config.lang(TypeOfSignature === '001' ? 'Ngay_bat_dau_HDLD' : 'Ngay_bat_dau_PLHDLD')}
                    dataField={'StartDate'}
                    dataType={'date'}
                    format={'dd/MM/yyyy'}
                    filterPlaceholder={'DD/MM/YYYY'}
                    alignment={'right'}
                    minWidth={90}
                />
                <Column
                    caption={Config.lang(TypeOfSignature === '001' ? 'Ngay_ket_thuc_HDLD' : 'Ngay_ket_thuc_PLHDLD')}
                    dataField={'EndDate'}
                    dataType={'date'}
                    format={'dd/MM/yyyy'}
                    filterPlaceholder={'DD/MM/YYYY'}
                    alignment={'right'}
                    minWidth={90}
                />
                <Column
                    caption={Config.lang('Ngay_chuyen')}
                    dataField={'TransferDate'}
                    dataType={'date'}
                    format={'dd/MM/yyyy'}
                    filterPlaceholder={'DD/MM/YYYY'}
                    alignment={'right'}
                    minWidth={90}
                />
                <Column
                    caption={Config.lang('Trang_thai')}
                    dataField={'SignStatusID'}
                    dataType={'boolean'}
                    allowFiltering={false}
                    cellRender={renderSignStatus}
                    minWidth={120}
                />
                <Column
                    fixed={true}
                    fixedPosition={'right'}
                    visible={!Config.isMobile}
                    alignment={'center'}
                    cellRender={renderAction}
                    minWidth={90}
                />
            </GridContainer>
        </>
    );
};

export default W09F2310;
