import React, {useState, useEffect} from 'react';
import {Col, Row} from "react-bootstrap";
import {makeStyles} from "diginet-core-ui/theme";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Config from "../../../../config";
import {Button, ButtonIcon, DatePicker, Dropdown, Tooltip, Avatar, Typography} from "diginet-core-ui/components";
import { CalendarNew } from 'diginet-core-ui/icons';
import GridContainer from "../../../grid-container/grid-container";
import _ from "lodash";
import {Column} from "devextreme-react/data-grid";
import moment from "moment";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W09F2290Actions from "../../../../redux/W0X/W09F2290/W09F2290_actions";
import Filter from "../../../filter/filter";
import Status from "../../../common/status/status";
import W09F2291 from "../W09F2291/W09F2291";
import {browserHistory} from "react-router";
import W84F3005 from "../../../W8X/W84/W84F3005/W84F3005";
import History from "../../../libs/history";

const useStyles = makeStyles(() => ({
    dateInfo: {
        display: 'flex',
        alignItems: 'center'
    },
    divText: {
        fontWeight: 500,
    },
    VoucherNo: {
        maxWidth: 500,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        textAlign: 'left'
    },
    truncatedText: {
        maxWidth: 750,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    }
}));
const FormID = "W09F2290";
const Language = Config.language || "84";

const W09F2290 = ({w09f2290Actions, generalActions, getCboAppStatus, getCboDuty, getCboTransTypes}) => {
    const classes = useStyles();
    const [dataGrid, setDataGrid] = useState({rows: [], total: 0});
    const [dataCbEmployees, setDataCbEmployees] = useState({rows: [], total: 0});

    const [isLoadingDataGrid, setLoadingDataGrid] = useState(false);
    const [isLoadingCbAppStatus, setLoadingCbAppStatus] = useState(false);
    const [isLoadingCbEmployee, setLoadingCbEmployee] = useState(false);
    const [isLoadingCbDuty, setLoadingCbDuty] = useState(false);
    const [isLoadingCbTransTypes, setLoadingCbTransTypes] = useState(false);

    const [isPermission, setIsPermission] = useState({W09F2290: 0, W09F5602: 0});

    const [showW09F2291, setShowW09F2291] = useState({isShowing: false, data: {}, mode: ""});
    const [showW84F3005, setShowW84F3005] = useState({isShowing: false, transID: ""})

    const [paging, setPaging] = useState({skip: 0, limit: 10});
    const [pagingCbEmployee, setPagingEmployee] = useState({strSearch: "", skip: 0, limit: 10});

    const [filters, setFilters] = useState({
        ProDateFrom: null,
        ProDateTo: null,
        EmployeeID: "",
        TransTypeID: "",
        DutyID: "",
        AppStatusID: ""
    });
    const [strFiltering, setStrFiltering] = useState("reset");

    useEffect(() => {
        const {ProDateFrom, ProDateTo, EmployeeID, TransTypeID, DutyID, AppStatusID} = filters;
        const {skip, limit} = paging;
        const params = {
            Language,
            ProDateFrom,
            ProDateTo,
            EmployeeID,
            TransTypeID,
            DutyID,
            AppStatusID: _.toString(AppStatusID),
            skip,
            limit
        };
        setLoadingDataGrid(true);
        w09f2290Actions.loadDataGrid(params, (err, data) => {
            setLoadingDataGrid(false)
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
            if (data) {
                const rows = _.get(data, "rows", []);
                const total = _.get(data, "total", 0);
                setDataGrid({rows, total});
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paging.skip, paging.limit, strFiltering]);

    useEffect(() => {
        setLoadingCbAppStatus(true)
        generalActions.getCboAppStatus({FormID, Language}, (err) => {
            setLoadingCbAppStatus(false)
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const {skip, limit, strSearch} = pagingCbEmployee;
        const param = {
            skip,
            limit,
            FormID,
            Language,
            search: strSearch,
            Type: "EmployeeID",
        };
        setLoadingCbEmployee(true);
        generalActions.getCboEmployees(param, (err, data) => {
            setLoadingCbEmployee(false);
            if (err) {
                Config.popup.show("ERROR", _.get(err, "message", Config.lang("Loi_chua_xac_dinh")));
                return false;
            }
            if (data) {
                const rows = _.get(data, "rows", []);
                const total = _.get(data, "total", 0);
                setDataCbEmployees({
                    rows: strSearch ? rows : dataCbEmployees.rows.concat(rows),
                    total
                });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagingCbEmployee.skip, pagingCbEmployee.limit, pagingCbEmployee.strSearch]);

    useEffect(() => {
        setLoadingCbDuty(true)
        generalActions.getCboDuty2(err => {
            setLoadingCbDuty(false)
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setLoadingCbTransTypes(true);
        generalActions.getCboTransTypes(err => {
            setLoadingCbTransTypes(false);
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async () => {
            await generalActions.getPermission(["W09F2290", "W09F5602"], (arrPer) => {
                const permissions = {};
                if(Array.isArray(arrPer)) {
                    arrPer.forEach(per => {
                        permissions[per.FormID] = per?.IsPermission || 0;
                    });
                }
                setIsPermission(permissions);
            });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangePage = (page) => setPaging({...paging, skip: page * paging.limit});

    const onChangePerPage = (perPage) => setPaging({skip: 0, limit: perPage});

    const onFilterChange = (key, e) => {
        const value = _.get(e, "value", _.get(e, "target.value", ""));
        const filtersValue = {...filters, [key]: value};
        if (!_(filtersValue).mapValues(_.toString).omitBy(_.isEmpty).size()) setStrFiltering("reset");
        setFilters(filtersValue);
    };

    const onFiltering = () => setStrFiltering(_.join(_.values(filters), "-"));

    const onDelete = (data) => {
        const ProTransID = _.get(data, "ProTransID", "");
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_xoa?"), () => {
            w09f2290Actions.deleteRow({ProTransID: ProTransID}, async err => {
                if (err) {
                    Config.popup.show("ERROR", err);
                    return false;
                }
                Config.notify.show("success", Config.lang("Xoa_thanh_cong"), 3000);
                setStrFiltering(`deleted ${ProTransID}`);
                await saveHistory(data);
            })
        });
    };

    const onOpenW09F2291 = (mode = "add", data = {}) => setShowW09F2291({isShowing: true, data, mode});

    const onCloseW09F2291 = (isReload = false) => {
        if (isReload) setStrFiltering("reload");
        setShowW09F2291({isShowing: false, data: {}, mode: ""})
    }

    const onCloseW84F3005 = () => {
        setShowW84F3005({isShowing: false, transID: ""})
    }

    const onOpenW84F3005 = (data) => {
        setShowW84F3005({isShowing: true, transID: _.get(data, "ProTransID", "")})
    }

    const onRedirectToW09F2000 = (data) => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W09F2000",
            state: {EmployeeID: _.get(data, "EmployeeID", "")}
        });
    }

    const renderEmpProfile = (e) => {
        const {data} = e.row;
        const dataEmployee = Config.getUser({EmployeeID: _.get(data, "EmployeeID", "")});
        const date = moment.utc(data.ProposalDate).isValid() ?
            moment.utc(data.ProposalDate).format("LT, DD/MM/YYYY") : "";
        return (
            <div className={"display_row align-center pdt10 pdb10"}
                 style={{width: '100%'}}
            >
                <Avatar
                    onClick={() => onRedirectToW09F2000(data)}
                    clearAble={false}
                    width={64}
                    height={64}
                    readOnly
                    data={{
                        [Config.lang("Nhan_vien")]: `${dataEmployee?.EmployeeID} - ${dataEmployee?.EmployeeName}`,
                        [Config.lang("Phong_ban")]: dataEmployee?.DepartmentName,
                        [Config.lang("Ngay_vao_lam")]: Config.convertDate(dataEmployee?.DateJoined, '', 'L'),
                    }}
                    hoverAble
                    src={dataEmployee?.UserPictureURL || ''}
                />
                <div className={'mgl10'}>
                    <Typography type={"h3"}>
                        {dataEmployee?.EmployeeName}
                    </Typography>
                    <div className={"display_row align-center mgt5"}>
                        <CalendarNew
                            height={20}
                            viewBox
                            width={20}
                        />
                        <Typography type={"p2"}
                                    style={{paddingLeft: 5}}
                                    color={Config.coreTheme?.colors?.placeholder}>
                            {date}
                        </Typography>
                    </div>
                </div>
            </div>
        );
    };

    const renderInfo = (e) => {
        const {data} = e.row;
        const dataInfo = _.filter([
            {
                label: Config.lang("Nghiep_vu"),
                value: data?.TransTypeName
            },
            {
                label: Config.lang("Chuc_vu_moi"),
                value: data?.ProDutyName
            },
            {
                label: Config.lang("Ngay_hieu_luc"),
                value: moment.utc(data?.ProDutyDate).isValid() ? moment.utc(data?.ProDutyDate).format("DD/MM/YYYY") : ""
            },
            {
                label: Config.lang("Nguoi_lap"),
                value: data?.ProposerName
            },
            {
                label: Config.lang("Ghi_chu"),
                value: data?.ProNotice
            }
        ], item => !!item.value);
        return <>
            {_.map(dataInfo, (item, i) =>
                <Tooltip direction="down"
                         alignMode={"flex-start"}
                         forceDirection
                         title={item.label === Config.lang("Ghi_chu") ? item.value : ""}
                >
                    <div key={i} style={{display: "flex", alignItems: "center"}}>
                        <div>{item.label}</div>
                        <div style={{paddingRight: 5}}>:</div>
                        <div
                            className={item.label === Config.lang("Ghi_chu") ? classes.truncatedText : ""}>{item.value}</div>
                    </div>
                </Tooltip>)}
        </>;
    };

    const renderAppStatus = (e) => {
        const {data} = e.row;
        const status = getCboAppStatus.find(rs => Number(rs.AppStatusID) === Number(data.AppStatusID));
        return status && <Status data={status}/>;
    }

    const renderAction = (e) => {
        const {data} = e.row;
        const disabledEdit = !!_.toNumber(_.get(data, "AppStatusID", 1));
        return (<div className={"display_row align-center"}>
            <ButtonIcon
                className={"mgr5"}
                circular={true}
                viewType={"text"}
                name={"History"}
                onClick={() => onOpenW84F3005(data)}
            />
            <ButtonIcon
                className={"mgr5"}
                disabled={disabledEdit}
                circular={true}
                viewType={"text"}
                name={"Edit"}
                onClick={() => onOpenW09F2291("edit", data)}
            />
            <ButtonIcon
                className={"mgr5"}
                circular={true}
                viewType={"text"}
                name={"Delete"}
                disabled={isPermission?.W09F2290 < 3 || disabledEdit}
                onClick={() => onDelete(data)}
            />
        </div>)
    }

    const renderFilter = () => {
        return (
            <Filter
                placeholder={Config.lang("Noi_dung_can_tim_kiem")}
                showHeader={true}
                isUseDDCore={true}
                openOnFieldClick={false}
                onOpenLoaded={() => false}
                renderFilter={() => {
                    return (
                        <>
                            <Row>
                                <Col xs={12} sm={6}>
                                    <DatePicker
                                        clearAble
                                        label={Config.lang("Tu")}
                                        width={"100%"}
                                        margin={"normal"}
                                        viewType={"outlined"}
                                        placeholder={"dd/mm/yyyy"}
                                        displayFormat={"DD/MM/YYYY"}
                                        max={filters.ProDateTo}
                                        value={filters.ProDateFrom}
                                        onChange={e => onFilterChange("ProDateFrom", e)}
                                    />
                                </Col>
                                <Col xs={12} sm={6}>
                                    <DatePicker
                                        label={Config.lang("Den")}
                                        clearAble
                                        width={"100%"}
                                        margin={"normal"}
                                        viewType={"outlined"}
                                        placeholder={"dd/mm/yyyy"}
                                        displayFormat={"DD/MM/YYYY"}
                                        min={filters.ProDateFrom}
                                        value={filters.ProDateTo}
                                        onChange={e => onFilterChange("ProDateTo", e)}
                                    />
                                </Col>
                                <Col xs={12}>
                                    <Dropdown
                                        clearAble
                                        viewType={"outlined"}
                                        valueExpr={"AppStatusID"}
                                        displayExpr={"AppStatusName"}
                                        placeholder={Config.lang("Chon")}
                                        label={Config.lang("Trang_thai_duyet")}
                                        onChange={e => onFilterChange("AppStatusID", e)}
                                        loading={isLoadingCbAppStatus}
                                        dataSource={getCboAppStatus}
                                        value={filters.AppStatusID}
                                    />
                                </Col>
                                <Col xs={12}>
                                    <Dropdown
                                        clearAble
                                        dataSource={dataCbEmployees.rows}
                                        total={dataCbEmployees.total}
                                        skip={pagingCbEmployee.skip}
                                        limit={pagingCbEmployee.limit}
                                        value={filters.EmployeeID}
                                        loading={isLoadingCbEmployee}
                                        viewType={"outlined"}
                                        valueExpr={"EmployeeID"}
                                        renderSelectedItem={"EmployeeName"}
                                        treeViewID={"EmployeeID"}
                                        treeViewParentID={"EmployeeName"}
                                        label={Config.lang("Nhan_vien")}
                                        searchDelayTime={500}
                                        placeholder={Config.lang("Chon")}
                                        inputProps={{
                                            onBlur: () => {
                                                if (!_.size(dataCbEmployees.rows)) {
                                                    setPagingEmployee({
                                                        ...pagingCbEmployee,
                                                        skip: 0,
                                                        strSearch: ""
                                                    })
                                                }
                                            }
                                        }}
                                        displayExpr={"{EmployeeID} - {EmployeeName}"}
                                        onChange={(e) => onFilterChange("EmployeeID", e)}
                                        onInput={(e) => {
                                            setPagingEmployee({
                                                ...pagingCbEmployee,
                                                skip: 0,
                                                strSearch: _.get(e, "target.value", "")
                                            })
                                        }}
                                        onLoadMore={(e) => setPagingEmployee({skip: e.skip, limit: e.limit})}
                                    />
                                </Col>
                                <Col xs={12}>
                                    <Dropdown
                                        clearAble
                                        viewType={"outlined"}
                                        valueExpr={"DutyID"}
                                        renderSelectedItem={"DutyName"}
                                        loading={isLoadingCbDuty}
                                        displayExpr={"{DutyID} - {DutyName}"}
                                        placeholder={Config.lang("Chon")}
                                        label={Config.lang("Chuc_vu")}
                                        onChange={e => onFilterChange("DutyID", e)}
                                        dataSource={getCboDuty}
                                        value={filters.DutyID}
                                    />
                                </Col>
                                <Col xs={12}>
                                    <Dropdown
                                        clearAble
                                        loading={isLoadingCbTransTypes}
                                        viewType={"outlined"}
                                        valueExpr={"TransTypeID"}
                                        displayExpr={"TransTypeName"}
                                        placeholder={Config.lang("Chon")}
                                        label={Config.lang("Nghiep_vu")}
                                        onChange={e => onFilterChange("TransTypeID", e)}
                                        dataSource={getCboTransTypes}
                                        value={filters.TransTypeID}
                                    />
                                </Col>
                            </Row>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <Button
                                    size={"medium"}
                                    color={"primary"}
                                    viewType={"outlined"}
                                    label={Config.lang("Tim_kiem")}
                                    startIcon={"Search"}
                                    onClick={onFiltering}
                                />
                            </div>
                        </>
                    );
                }}
            />
        );
    };

    const saveHistory = async (data) => {
        if (!data) return false;
        const ProTransID = data?.ProTransID;
        if (ProTransID) {
            const proBaseSalAndCoefficient = _.transform(data, (rs, v, k) => {
                const caption = _.startsWith(k, "ProBaseSalary") ? "Luong_co_ban" : "He_so_luong";
                if (_.startsWith(k, "ProSalCoefficient") || _.startsWith(k, "ProBaseSalary")) {
                    rs["data"] = {...rs["data"], [k]: v};
                    rs["caption"] = {...rs["caption"], [k]: caption + `_%${k.match(/\d+/g)[0]}%`};
                }
            }, {});
            // data Content
            let _data = {
                TransTypeID: data?.TransTypeID ?? "",
                TransTypeName: data?.TransTypeName ?? "",
                EmployeeID: data?.Employee ?? "",
                EmployeeName: data?.EmployeeName ?? "",
                ProDutyDate: data?.ProDutyDate ?? "",
                ProDutyID: data?.ProDutyID ?? "",
                ProDutyName: data?.DutyName ?? "",
                ProReasonID: data?.ProReasonID ?? "",
                ProReasonName: data?.ReasonName ?? "",
                IsSalAdjust: data?.IsSalAdjust ?? 0,
                ProNotice: data?.ProNotice,
                ...(proBaseSalAndCoefficient?.data ?? {}),
            };

            //name Content
            const captions = {
                TransTypeID: "Ma_nghiep_vu",
                TransTypeName: "Ten_nghiep_vu",
                EmployeeID: "Ma_nhan_vien",
                EmployeeName: "Ten_nhan_vien",
                ProDutyDate: "Ngay_hieu_luc",
                ProDutyID: "Ma_chuc_vu_moi",
                ProDutyName: "Ten_chuc_vu_moi",
                ProReasonID: "Ma_ly_do",
                ProReasonName: "Ly_do",
                ProNotice: "Ghi_chu",
                IsSalAdjust: "Dieu_chinh_luong",
                ...(proBaseSalAndCoefficient?.caption ?? {})
            };
            const options = {
                data: _data,
                captions: captions,
                action: 3,
                ModuleID: "D09",
                TransID: ProTransID,
                TransactionID: "W09F2290",
                TransactionName: "Đề xuất miễn nhiệm",
            };
            const history = new History(options); //Init history
            await history.save();
        } else {
            Config.notify.show("error", Config.lang("Luu_lich_su_khong_thanh_cong"), 2000);
        }
    }

    return (<>
            {showW84F3005.isShowing ? <W84F3005 open={showW84F3005.isShowing} onClose={onCloseW84F3005}
                                                FormID="W09F2225" TransID={showW84F3005.transID}/> : ""}
            {showW09F2291.isShowing ? <W09F2291 data={showW09F2291.data}
                                                mode={showW09F2291.mode}
                                                open={showW09F2291.isShowing}
                                                onClose={onCloseW09F2291}
                                                isPermission={isPermission.W09F5602}
            /> : ""}
            <div className={"hidden"}>{renderFilter()}</div>
            <ActionToolbar title={Config.lang("Mien_nhiem")}>
                <Button
                    disabled={isPermission?.W09F2290 < 2}
                    size={"medium"}
                    color={"primary"}
                    viewType={"filled"}
                    label={Config.lang("Them")}
                    style={{textTransform: 'uppercase'}}
                    startIcon={"AddCircle"}
                    onClick={() => onOpenW09F2291("add")}
                />
            </ActionToolbar>
            <GridContainer
                style={{border: "none"}}
                dataSource={dataGrid.rows}
                showRowLines={true}
                showBorders={false}
                showColumnLines={false}
                showColumnHeaders={false}
                height={Config.getHeightGrid()}
                onChangePage={onChangePage}
                onChangePerPage={onChangePerPage}
                noDataText={Config.lang("No_data")}
                loading={isLoadingDataGrid}
                hoverStateEnabled={true}
                totalItems={dataGrid.total}
                itemPerPage={paging.limit}
                skipPerPage={paging.skip}
                typePaging={"remote"}
                onDbRowClick={({data}) => onOpenW09F2291("view", data)}
            >
                <Column cellRender={renderEmpProfile} width={300}/>
                <Column cellRender={renderInfo} minWidth={150}/>
                <Column cellRender={renderAppStatus} alignment="right" width={150}/>
                <Column cellRender={renderAction} alignment="left" width={150}/>
            </GridContainer>
        </>
    )
}

export default connect(state => ({
    getCboAppStatus: state.general.getCboAppStatus,
    getCboDuty: state.general.getCboDuty2,
    getCboTransTypes: state.general.getCboTransTypes,
}), dispatch => ({
    generalActions: bindActionCreators(generalActions, dispatch),
    w09f2290Actions: bindActionCreators(W09F2290Actions, dispatch)
}))(W09F2290);